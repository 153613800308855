import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { BtnSwitch, IconBtn } from '../Utils/Button';

const ValidationSwitchButton = ({
  id,
  isAnalyzed,
  isValidated,
  lastValidatedDate,
  updateRequest,
  setMoreData,
  onValidateContentAi,
  disableValidateButton,
  readonly
}) => {
  const dispatch = useDispatch();

  const validateIcon = () => {
    return isValidated ? 'success' : 'danger';
  };

  const analyzedIcon = () => {
    return isAnalyzed ? 'primary-light' : 'disabled';
  };

  const getIconVariant = () => {
    let variant = 'primary';
    let icon = 'check';
    if (isAnalyzed) {
      variant = isValidated ? 'success-validated' : 'danger-validated';
      icon = 'check-o';
    } else {
      variant = 'primary-validated';
      icon = 'check-o';
    }
    return { name: icon, variant };
  };

  const getCardVariant = () => {
    if (isAnalyzed) {
      return isValidated ? 'success' : 'danger';
    }
    return 'disabled';
  };

  const handleValidationUpdate = ({ attribute, value }) => {
    updateRequest(id, {
      dispatch,
      params: { insurance_policy: { [attribute]: value } },
      formData: true,
      successCallback: () => {
        setMoreData(m => !m);
      }
    });
  };

  return (
    <div className={`statistic-card ${getCardVariant()} position-relative`}>
      <div className="statistic-card--icon">
        <IconBtn
          icon={getIconVariant().name}
          iconSize="lg"
          variant={getIconVariant().variant}
          noBtn
          className="no-shadow"
        />
      </div>
      <div className="d-flex flex-column w-100 justify-content-start">
        <div>
          <p className="section-title m-1 capitalize-text">Validar</p>
          <p className="detail mt-4">Últ: {lastValidatedDate || 'Sin data'}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <BtnSwitch
              id="analyzed-switch"
              initialValue={isAnalyzed}
              onClickChecked={() => handleValidationUpdate({ attribute: 'is_document_analyzed', value: true })}
              onClickUnchecked={() => handleValidationUpdate({ attribute: 'is_document_analyzed', value: false })}
              readonly={readonly}
              variant={analyzedIcon()}
            />
          </div>
          <span className="subtitle font-weight-500">Analizada</span>
        </div>

        {isAnalyzed && (
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <BtnSwitch
                id="validated-switch"
                initialValue={isValidated}
                onClickChecked={() => handleValidationUpdate({ attribute: 'ai_validated', value: true })}
                onClickUnchecked={() => handleValidationUpdate({ attribute: 'ai_validated', value: false })}
                readonly={readonly}
                variant={validateIcon()}
              />
            </div>
            <span className="subtitle font-weight-500">Validada</span>
          </div>
        )}

        <div className="d-flex justify-content-end w-100">
          <IconBtn onClick={onValidateContentAi} disabled={disableValidateButton || readonly}>
            Validar
            <span role="img" aria-label="sparkles">
              ✨
            </span>
          </IconBtn>
        </div>
      </div>
    </div>
  );
};

ValidationSwitchButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isAnalyzed: PropTypes.bool,
  isValidated: PropTypes.bool,
  lastValidatedDate: PropTypes.string,
  updateRequest: PropTypes.func.isRequired,
  setMoreData: PropTypes.func.isRequired,
  onValidateContentAi: PropTypes.func,
  disableValidateButton: PropTypes.bool,
  readonly: PropTypes.bool
};

ValidationSwitchButton.defaultProps = {
  isAnalyzed: false,
  isValidated: false,
  lastValidatedDate: null,
  disableValidateButton: false,
  readonly: false,
  onValidateContentAi: null
};

export default ValidationSwitchButton;
