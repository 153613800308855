import React, { useState } from 'react';
import { Button, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { checkAdminThreadAi } from '../../../requests/admin/lead';
import { ModalBodyIcon } from '../../Shared';
import { sendAlert } from '../../../actions/utils';
import { readAdminInsurancePolicyAiRequest } from '../../../requests/admin/adminInsurancePolicies';
import { BasicTextArea } from '../../Utils/Input';
import { generatePrompt } from './prompts';
import { camelCaseEmptyStringRecursive, isObject } from '../../../services/utils';
import ResultAiTable from './ResultAiTable';
import IconGG from '../../Utils/Icon';
import AiParameterSelect from './AiParameterSelect';
import { readInsurancePolicyAiRequest } from '../../../requests/insurancePolicies';
import { checkThreadAi } from '../../../requests/lead';

const ValidateContentAiModal = ({
  handleModalClose,
  policyFile,
  attachmentId,
  validateAiPrompt,
  options,
  onCompletedStatus,
  insurancePolicy,
  userModule
}) => {
  const { insuranceCompanyId, insuranceCategoryName } = insurancePolicy;
  const variant = 'submit';
  const iconVariant = 'success';
  const icon = 'check-o';
  const dispatch = useDispatch();
  const [resultAi, setResultAi] = useState('');
  const [loading, setLoading] = useState(false);
  const [additionalInstruction, setAdditionalInstruction] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedAiParameter, setSelectedAiParameter] = useState('');
  const [isValid, setIsValid] = useState(false);
  const RETRY_DELAY = 10000;

  const modalTitle = 'Validar póliza ✨';

  const submitButtonText = () => {
    if (loading) {
      return (
        <>
          Analizando...
          <span role="img" aria-label="sparkles">
            ✨
          </span>
          <Spinner animation="border" variant="primary" className="ml-2" />
        </>
      );
    }

    return resultAi ? 'Guardar' : 'Leer ✨';
  };

  const handleSave = () => {
    onCompletedStatus({ aiValidated: isValid, isDocumentAnalyzed: Boolean(resultAi), lastValidatedAt: new Date() });
  };

  const handleCloseWithoutSave = () => {
    onCompletedStatus({ aiValidated: null, isDocumentAnalyzed: Boolean(resultAi), lastValidatedAt: new Date() });
  };

  const handleIsValidated = jsonContent => {
    if (!jsonContent || !jsonContent.items || !Array.isArray(jsonContent.items)) {
      setIsValid(false);
    }

    const hasFailure = jsonContent.items.some(item => item.result && item.result.includes('❌'));
    setIsValid(!hasFailure);
  };

  const getReadPolicyAIRequest = () => {
    const requestMap = {
      admin: readAdminInsurancePolicyAiRequest,
      broker: readInsurancePolicyAiRequest
    };

    return requestMap[userModule];
  };

  const getCheckThreadAiRequest = () => {
    const requestMap = {
      admin: checkAdminThreadAi,
      broker: checkThreadAi
    };

    return requestMap[userModule];
  };

  const handleCompletedStatus = message => {
    const jsonMatch = message.match(/```json\n([\s\S]*?)\n```/);
    // setResultAi(message);
    setLoading(false);
    if (jsonMatch) {
      try {
        const jsonObject = JSON.parse(jsonMatch[1]);
        const parsedObject = camelCaseEmptyStringRecursive(jsonObject);
        setResultAi(parsedObject);
        handleIsValidated(parsedObject);
      } catch (error) {
        dispatch(sendAlert({ kind: 'error', message: 'Error al parsear JSON' }));
      }
    }
  };

  const handleFailedStatus = message => {
    setResultAi(message);
    setLoading(false);
    setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
  };

  const handleCheckThread = ({ theadId, runId, attempt = 1 }) => {
    const endpointRequest = getCheckThreadAiRequest();
    const sendParams = { thread_id: theadId, run_id: runId };
    endpointRequest({
      dispatch,
      formData: true,
      params: sendParams,
      successCallback: response => {
        const { message, status } = response.data;

        if (status === 'completed') {
          handleCompletedStatus(message);
        } else if (status === 'failed') {
          handleFailedStatus(message);
        } else {
          setTimeout(() => {
            handleCheckThread({ theadId, runId, attempt: attempt + 1 });
          }, RETRY_DELAY);
        }
      }
    });
  };

  const handleReadSuccessRequest = response => {
    if (!response.data) {
      setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
      setLoading(false);
      return;
    }
    const { thread_id: theadId, run_id: runId } = response.data;
    setTimeout(() => {
      handleCheckThread({ theadId, runId });
    }, RETRY_DELAY);
  };

  const completePrompt = () => {
    return generatePrompt({
      insuranceCategoryName,
      additionalInstruction,
      validateAiPrompt: selectedAiParameter?.validate_ai_params || validateAiPrompt
    });
  };

  const handleReadPolicyAIRequest = () => {
    setLoading(true);
    const sendParams = {
      prompt: completePrompt(),
      file: isObject(policyFile) ? policyFile : null,
      attachment_id: isObject(policyFile) ? null : attachmentId,
      action_ai: 'validate',
      insurance_company_id: insuranceCompanyId
    };

    const endpointRequest = getReadPolicyAIRequest();

    endpointRequest({
      dispatch,
      params: { insurance_policy: sendParams },
      formData: true,
      successCallback: response => handleReadSuccessRequest(response),
      failureCallback: () => {
        setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
        setLoading(false);
      }
    });
  };

  const disabledButton = () => {
    return loading || (!selectedAiParameter?.validate_ai_params && !validateAiPrompt);
  };

  const generateColumns = () => [
    { columnName: 'Puntos', columnStyle: { width: '200px' }, selector: 'title' },
    { columnName: 'Resultado', columnStyle: { width: '200px' }, selector: 'result' }
  ];

  const generateTableBody = items => {
    return items.map(item => {
      if (item.explanation) {
        return {
          ...item,
          result: `${item.result} - ${item.explanation}`
        };
      }
      return item;
    });
  };

  return (
    <>
      <ModalBodyIcon
        icon={icon}
        size="xl"
        iconVariant={iconVariant}
        content={modalTitle}
        confirmText={false}
        subContent={
          <>
            <Row>
              {!resultAi && (
                <>
                  {errorMessage && (
                    <Col md={12} className="d-flex align-items-center justify-content-center">
                      <Alert variant="danger" className="d-flex align-items-center justify-content-center">
                        <IconGG className="mr-2" icon="danger" size="sm" />
                        <p className="detail font-weight-bold m-0">{errorMessage}</p>
                      </Alert>
                    </Col>
                  )}

                  <Col md={12}>
                    <p>
                      Estás validando una póliza del ramo {`"${insuranceCategoryName}"`}. Elige el listado de parámetros
                      que quieres usar para la validación
                    </p>
                    <AiParameterSelect
                      selectedAiParameter={selectedAiParameter}
                      setSelectedAiParameter={setSelectedAiParameter}
                      options={options}
                      disabled={options.length === 0}
                    />
                  </Col>

                  {selectedAiParameter && (
                    <Col md={12}>
                      <p>Puntos a validar:</p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>{selectedAiParameter.validate_ai_params}</p>
                    </Col>
                  )}

                  {!selectedAiParameter && validateAiPrompt && (
                    <Col md={12}>
                      <p>Puntos a validar por defecto:</p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>{validateAiPrompt}</p>
                    </Col>
                  )}

                  <Col md={12}>
                    <BasicTextArea
                      label="Instrucción adicional"
                      onChange={e => {
                        setAdditionalInstruction(e.target.value);
                      }}
                      placeholder="Instrucción adicional"
                      value={additionalInstruction}
                      row={3}
                    />
                  </Col>
                </>
              )}
            </Row>

            {resultAi && (
              <div>
                <ResultAiTable columns={generateColumns()} tableBody={generateTableBody(resultAi.items)} />
                <div className="mt-4 p-3 border rounded-medium bg-light">
                  <h5 className="font-weight-bold">Resultado análisis:</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-2">
                      <span className="mr-2">Último análisis:</span>
                      <span>
                        {new Date().toLocaleString('es-ES', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="mr-2">Válida:</span>
                      {isValid ? (
                        <>
                          <span className="text-success mr-2">Sí</span>
                          <IconGG icon="check" size="sm" variant="success" />
                        </>
                      ) : (
                        <>
                          <span className="text-danger mr-2">No</span>
                          <IconGG icon="close" size="sm" variant="danger" />
                        </>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </>
        }
        formContent={
          <Row className="justify-content-center">
            <Col md={6} className="mt-4">
              <Button
                block
                variant="cancel"
                className="no-shadow"
                onClick={resultAi ? handleCloseWithoutSave : handleModalClose}
              >
                Cerrar
              </Button>
            </Col>
            <Col md={6} className="mt-4">
              <Button
                block
                type="submit"
                variant={variant}
                className="no-shadow"
                onClick={resultAi ? handleSave : handleReadPolicyAIRequest}
                disabled={disabledButton()}
              >
                {submitButtonText()}
              </Button>
            </Col>
          </Row>
        }
        highlightedText=""
        highlightedVariant="white"
        handleModalClose={resultAi ? handleCloseWithoutSave : handleModalClose}
        submitVariant={variant}
      />
    </>
  );
};

export default ValidateContentAiModal;
