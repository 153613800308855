import React from 'react';

const PaymentTextDescription = () => {
  const commonClasses = 'bg-light-gray px-3 rounded-pill font-weight-bold';

  return (
    <>
      <p>Para cargar el listado de comisiones pagadas a los asociados:</p>
      <ul>
        <li className="ml-4">
          Ingresa una póliza o endoso por línea con los siguientes datos: N° póliza; Nº endoso (si corresponde); Número
          de factura del Asociado; Fecha de facturación; Mes de cobertura (si corresponde a cadencia mensual).
        </li>
        <li className="ml-4">
          Copia los datos desde un documento <span className={commonClasses}>XLSX</span> o separa cada dato de la póliza
          utlizando <span className={commonClasses}>;</span>
        </li>
      </ul>
    </>
  );
};

export default PaymentTextDescription;
