import React from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

import { IconBtn } from '../Button';

const InsuredSidebarLinks = ({ isActive, handleCloseSidebar }) => (
  <>
    <SidebarLink
      eventKey="home"
      icon="home-alt"
      optionTitle="Inicio"
      optionPath="/insured/home"
      onClick={handleCloseSidebar}
      isActivePath={isActive}
    />
    <SidebarLink
      eventKey="insurance_policies"
      icon="notes"
      optionTitle="Pólizas"
      optionPath="/insured/insurance_policies"
      onClick={handleCloseSidebar}
      isActivePath={isActive}
    />
    <SidebarLink
      eventKey="leads"
      icon="calculator"
      optionTitle="Cotizaciones"
      optionPath="/insured/leads"
      onClick={handleCloseSidebar}
      isActivePath={isActive}
    />
    <SidebarLink
      eventKey="premium_collections"
      icon="list-tree"
      optionTitle="Cobranza"
      optionPath="premium_collections"
      onClick={handleCloseSidebar}
      isActivePath={isActive}
    />
    {/* <SidebarLink
      eventKey="commission_collections"
      icon="list-tree"
      optionTitle="Comisiones"
      optionPath="/insured/commission_collections"
      onClick={handleCloseSidebar}
      isActivePath={isActive}
    /> */}
    <SidebarLink
      eventKey="sinister"
      icon="smile-sad"
      optionTitle="Siniestros"
      optionPath="/insured/claims"
      onClick={handleCloseSidebar}
      isActivePath={isActive}
    />
    <SidebarLink
      eventKey="contact"
      icon="user"
      optionTitle="Contacto"
      optionPath="/insured/contact"
      onClick={handleCloseSidebar}
      isActivePath={isActive}
    />
  </>
);

const SidebarLink = ({ badge = false, icon, isActivePath, optionPath, optionTitle, ...props }) => (
  <Nav.Link as={Link} to={optionPath} active={isActivePath(optionPath)} {...props}>
    <span className="link-menu">
      <IconBtn icon={icon} variant="disabled" iconSize="sm" className="btn mr-4" />
      {optionTitle}
      {badge && <span className="link-menu--badge">{badge}</span>}
    </span>
  </Nav.Link>
);

export default InsuredSidebarLinks;
