const brokerLinks = [
  {
    href: '/corredor',
    text: 'Inicio',
    key: 'broker_landing'
  },
  {
    href: '/corredor/planes',
    text: 'Planes',
    key: 'broker_plans'
  },
  {
    href: 'https://blog.nicoseguros.com/',
    text: 'Blog',
    key: 'broker_plans'
  }
  // {
  //   href: '/broker/blog',
  //   text: 'Blog',
  //   key: 'broker_blog'
  // }
];

const partnerLinks = [
  {
    href: '/partner',
    text: 'Inicio',
    key: 'partner_landing'
  },
  {
    href: '/partner/como',
    text: 'Cómo funciona',
    key: 'partner_how_does_it_work'
  },
  {
    href: 'https://blog.nicoseguros.com/',
    text: 'Blog',
    key: 'blog_nico'
  }
  // {
  //   href: '/partner/blog',
  //   text: 'Blog',
  //   key: 'partner_blog'
  // }
];

const insuredLinks = [
  {
    href: '/asegurado',
    text: 'Inicio',
    key: 'insured_landing'
  },
  {
    href: '/asegurado/companias',
    text: 'Empresas',
    key: 'insured_companies'
  },
  {
    href: '/asegurado/personas',
    text: 'Personas',
    key: 'insured_persons'
  },
  {
    href: 'https://blog.nicoseguros.com/',
    text: 'Blog',
    key: 'broker_plans'
  }
  // {
  //   href: '/insured/blog',
  //   text: 'Blog',
  //   key: 'insured_blog'
  // }
];

const companyLinks = [];

const navbarItems = role => {
  switch (role) {
    case 'broker':
      return brokerLinks;
    case 'partner':
      return partnerLinks;
    case 'insured':
      return insuredLinks;
    case 'company':
      return companyLinks;
    default:
      return [];
  }
};

export default navbarItems;
