import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexInsurancePolicyRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/insurance_policies', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const showInsurancePolicyRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/insurance_policies/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const createInsurancePolicyRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/insurance_policies', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateInsurancePolicyRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/insurance_policies/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteInsurancePolicyRequest = (id, { dispatch, successCallback, failureCallback, callback, source }) =>
  ApiService.request('delete', `/insurance_policies/${id}`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const sendContractInsurancePolicyRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('post', `/insurance_policies/${id}/send_contract`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const renewalTypeCounterInsurancePolicyRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/insurance_policies/renewal_type_counter', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const indexInsuranceItemRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/insurance_policies/${id}/insurance_items`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const associateContractItemsRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/insurance_policies/${id}/associate_contract_items`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    showLeafArrayIndexes: true,
    source
  });

export const readInsurancePolicyAiRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/insurance_policies/read_policy_ai', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceIndexInsurancePoliciesRequest = AwesomeDebouncePromise(indexInsurancePolicyRequest, 1000);
export const debounceUpdateInsurancePolicyRequest = AwesomeDebouncePromise(updateInsurancePolicyRequest, 1000);
export const debounceIndexInsuranceItemRequest = AwesomeDebouncePromise(indexInsuranceItemRequest, 1000);
