/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import {
  BasicActionBtns,
  BtnTooltip,
  DataTableDatePicker,
  DataTableInput,
  DatatableMainInfo,
  DataTableNumberFormat,
  DataTableSelect,
  ReadMore
} from '..';
import { statusOptions } from './attributeOptions';
import basicInfoRoles from './basicInfoRoles';

const columns = memoize(({ handleButtonClick, handleUpdateRequest, userModule }) => {
  const currentUserOptions = basicInfoRoles[userModule];
  const { basePath, can, isAdmin } = currentUserOptions;

  const premiumCollectionColumns = [
    {
      name: 'Cadencia',
      selector: 'cadence_type',
      sortable: true,
      grow: '1',
      cell: ({ insurancePolicyCadenceType, translatedInsurancePolicyCadenceType }) => (
        <BtnTooltip
          icon={insurancePolicyCadenceType.includes('unique') ? 'calendar-due' : 'calendar-next'}
          iconSize="xl"
          variant="transparent"
          className="black"
          tooltipText={translatedInsurancePolicyCadenceType}
        />
      )
    },
    {
      name: 'Cuenta',
      selector: 'account',
      sortable: true,
      grow: '1',
      minWidth: '250px',
      cell: ({ accountId, accountName, accountNationalIdentification }) => (
        <DatatableMainInfo
          noIcon
          linkTitle={can.redirectToAccount && `${basePath}/accounts/${accountId}`}
          title={accountName}
          subtitle={accountNationalIdentification}
        />
      )
    },
    {
      name: 'N° póliza',
      selector: 'policy_number',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({
        insurancePolicyId,
        insurancePolicyPolicyNumber,
        insurancePolicyValidityEnd,
        insurancePolicyValidityStart
      }) => (
        <DatatableMainInfo
          noIcon
          linkTitle={can.redirectToInsurancePolicy && `${basePath}/insurance_policies/${insurancePolicyId}`}
          title={insurancePolicyPolicyNumber}
          subtitle={`I: ${insurancePolicyValidityStart}`}
          secondarySubtitle={`V: ${insurancePolicyValidityEnd}`}
        />
      )
    },
    {
      name: 'N° endoso',
      selector: 'insurance_endorsement',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({
        insuranceEndorsementCompanyEndorsementNumber,
        insuranceEndorsementCompanyIssueDate,
        insuranceEndorsementId,
        insurancePolicyId
      }) =>
        insuranceEndorsementId ? (
          <DatatableMainInfo
            noIcon
            linkTitle={can.redirectToInsuranceEndorsement && `${basePath}/insurance_policies/${insurancePolicyId}`}
            title={insuranceEndorsementCompanyEndorsementNumber}
            subtitle={insuranceEndorsementId}
            secondarySubtitle={insuranceEndorsementCompanyIssueDate}
          />
        ) : (
          '-'
        )
    },
    {
      name: 'Compañía',
      selector: 'insurance_company',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ insuranceCompanyLabel }) => insuranceCompanyLabel
    },
    {
      name: 'Código NICO',
      selector: 'nico_code',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ translatedInsurancePolicyNicoCode }) => translatedInsurancePolicyNicoCode
    },
    {
      name: 'Fecha creación',
      selector: 'collection_date',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ collectionDate }) => collectionDate
    },
    {
      name: 'Mes de cobertura',
      selector: 'coverage_date',
      sortable: true,
      grow: '1',
      minWidth: '100px',
      cell: ({ coverageMonth }) => coverageMonth
    },
    {
      name: 'Estado',
      selector: 'status',
      sortable: true,
      grow: '1',
      minWidth: '260px',
      cell: ({ id, status, translatedStatus }) =>
        isAdmin ? (
          <DataTableSelect
            id={id}
            attribute="status"
            defaultValue={status}
            options={statusOptions}
            updateRequest={handleUpdateRequest}
            disabled={!can.edit}
            updateDataTable
          />
        ) : (
          translatedStatus
        )
    },
    {
      name: 'Comisión Póliza Calculada Total Neta CLP$',
      selector: 'total_net_commission_calculated',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ parsedTotalNetCommissionCalculated }) => parsedTotalNetCommissionCalculated
    }
  ];

  if (isAdmin) {
    premiumCollectionColumns.push({
      name: 'Comisión Póliza Informada Total Neta CLP$',
      selector: 'total_net_commission_reported',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ id, parsedTotalNetCommissionReported, totalNetCommissionReported }) =>
        isAdmin ? (
          <DataTableNumberFormat
            id={id}
            prefix="$ "
            attribute="total_net_commission_reported"
            defaultValue={totalNetCommissionReported}
            updateRequest={handleUpdateRequest}
            disabled={!can.edit}
            updateDataTable
          />
        ) : (
          parsedTotalNetCommissionReported || '-'
        )
    });
  }

  premiumCollectionColumns.push({
    name: 'N° factura',
    selector: 'invoice_number',
    sortable: true,
    grow: '1',
    minWidth: '170px',
    cell: ({ id, invoiceNumber }) =>
      isAdmin ? (
        <DataTableInput
          id={id}
          attribute="invoice_number"
          defaultValue={invoiceNumber}
          updateRequest={handleUpdateRequest}
          disabled={!can.edit}
          updateDataTable
        />
      ) : (
        invoiceNumber || '-'
      )
  });

  premiumCollectionColumns.push({
    name: 'N° factura Asociado',
    selector: 'associated_invoice_number',
    sortable: true,
    grow: '1',
    minWidth: '170px',
    cell: ({ id, associatedInvoiceNumber }) =>
      isAdmin ? (
        <DataTableInput
          id={id}
          attribute="associated_invoice_number"
          defaultValue={associatedInvoiceNumber}
          updateRequest={handleUpdateRequest}
          disabled={!can.edit}
          updateDataTable
        />
      ) : (
        associatedInvoiceNumber || '-'
      )
  });

  if (isAdmin) {
    premiumCollectionColumns.push(
      {
        name: 'Fecha emisión cobranza NICO',
        selector: 'nico_issue_date',
        sortable: true,
        grow: '1',
        minWidth: '170px',
        cell: ({ id, nicoIssueDate }) =>
          isAdmin ? (
            <DataTableDatePicker
              id={id}
              attribute="company_issue_date"
              defaultValue={nicoIssueDate}
              updateRequest={handleUpdateRequest}
              disabled={!can.edit}
              updateDataTable
            />
          ) : (
            nicoIssueDate || '-'
          )
      },
      {
        name: 'Fecha pago compañía',
        selector: 'company_payment_date',
        sortable: true,
        grow: '1',
        minWidth: '170px',
        cell: ({ id, companyPaymentDate }) =>
          isAdmin ? (
            <DataTableDatePicker
              id={id}
              attribute="company_payment_date"
              defaultValue={companyPaymentDate}
              updateRequest={handleUpdateRequest}
              disabled={!can.edit}
              updateDataTable
            />
          ) : (
            companyPaymentDate || '-'
          )
      },
      {
        name: 'Comisión Empresa Calculada Neta CLP$',
        selector: 'total_net_company_commission_calculated',
        sortable: true,
        grow: '1',
        minWidth: '170px',
        cell: ({ parsedNetCompanyCommissionCalculated }) => parsedNetCompanyCommissionCalculated
      }
    );
  }

  premiumCollectionColumns.push(
    {
      name: 'Comisión Empresa Informada Neta CLP$',
      selector: 'total_net_company_commission_reported',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ parsedNetCompanyCommissionReported }) => parsedNetCompanyCommissionReported
    },
    {
      name: 'Fecha pago asociado',
      selector: 'associated_payment_date',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ id, associatedPaymentDate }) =>
        isAdmin ? (
          <DataTableDatePicker
            id={id}
            attribute="associated_payment_date"
            defaultValue={associatedPaymentDate}
            updateRequest={handleUpdateRequest}
            disabled={!can.edit}
            updateDataTable
          />
        ) : (
          associatedPaymentDate || '-'
        )
    },
    {
      name: 'Mes de liquidación',
      selector: 'associated_payment_date',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ settlementMonth }) => settlementMonth
    }
  );

  if (isAdmin) {
    premiumCollectionColumns.push(
      {
        name: 'Ejecutivo',
        selector: 'executive_manager',
        sortable: true,
        grow: '1',
        minWidth: '170px',
        cell: ({ executiveManagerLabel }) => executiveManagerLabel
      },
      {
        name: 'Corredor',
        selector: 'insurance_broker',
        sortable: true,
        grow: '1.5',
        minWidth: '170px',
        cell: ({ insuranceBrokerCompanyName, insuranceBrokerName }) => (
          <DatatableMainInfo noIcon title={insuranceBrokerName} subtitle={insuranceBrokerCompanyName} />
        )
      }
    );
  }

  premiumCollectionColumns.push({
    name: 'Comentarios',
    selector: 'comments',
    sortable: true,
    grow: '1',
    minWidth: '170px',
    cell: ({ id, comments }) =>
      isAdmin && can.edit ? (
        <ReadMore
          isEditable
          title="Comentarios"
          item={{ id, attribute: 'comments', defaultValue: comments, handleUpdateRequest }}
        >
          {comments}
        </ReadMore>
      ) : (
        (comments && <ReadMore title="Comentarios">{comments}</ReadMore>) || '-'
      )
  });

  if (isAdmin && can.edit) {
    premiumCollectionColumns.push({
      grow: '1',
      cell: item => <BasicActionBtns withDestroy transparentStyle item={item} clickHandler={handleButtonClick} />
    });
  }

  return premiumCollectionColumns;
});

export default columns;
