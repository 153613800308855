import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import moment from 'moment';

import { BtnTooltip } from '../Utils/Button';
import {
  getAdminPremiumCollectionPaymentDataRequest,
  sendAdminReminderRequest
} from '../../requests/admin/adminPremiumCollections';
import { premiumCollectionDebtProps, premiumCollectionMonthlyProps } from '../Shared/SendEmailModalProps';
import SendDocumentEmail from '../Shared/SendDocumentEmail';
import { getPremiumCollectionPaymentDataRequest, sendReminderRequest } from '../../requests/premiumCollections';

const SendEmailBtn = ({ collectionItem, userModule }) => {
  const [premiumCollection, setPremiumCollection] = useState(null);
  const dispatch = useDispatch();

  const handleSendEmailRequest = (params, setSubmitting, handleSuccessRequest) => {
    const endpointRequest = userModule === 'admin' ? sendAdminReminderRequest : sendReminderRequest;

    endpointRequest(collectionItem.id, {
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  const { SendEmailModal, openEmailModal } = SendDocumentEmail({
    showCcField: true,
    showBccField: true,
    fileSection: true,
    fileSectionLabel: 'Adjuntos',
    textCancelButton: 'Cerrar',
    fromAdmin: true,
    customHandleSendEmailRequest: handleSendEmailRequest
  });

  const currentDate = moment().format('DD/MM/YYYY');

  const buildPremiumCollection = updatedData => {
    const baseData = {
      ...updatedData,
      accountName: collectionItem.accountName,
      additionalDocumentsInfo: collectionItem.additionalDocumentsInfo,
      insurancePolicyPolicyNumber: collectionItem.insurancePolicyPolicyNumber
    };

    if (collectionItem.createdFrom === 'monthly_cadence') {
      return {
        ...baseData,
        accountNationalId: collectionItem.accountNationalIdentification,
        invoiceNumber: collectionItem.invoiceNumber,
        parsedTotalPremiumReported: collectionItem.parsedTotalPremiumReported,
        currentDate
      };
    }

    return {
      ...baseData,
      insuranceBrokerName: collectionItem.insuranceBrokerName
    };
  };

  const handleGetPaymentData = () => {
    const paymentDataEndpoint =
      userModule === 'admin' ? getAdminPremiumCollectionPaymentDataRequest : getPremiumCollectionPaymentDataRequest;
    paymentDataEndpoint(collectionItem.id, {
      dispatch,
      params: {},
      successCallback: response => {
        const updatedData = camelCaseRecursive(response.data);
        setPremiumCollection(buildPremiumCollection(updatedData));
      }
    });
  };

  const handleOpenEmailModal = () => {
    if (premiumCollection) {
      openEmailModal();
    }
  };

  const getSendEmailProps = () => {
    if (collectionItem.createdFrom === 'monthly_cadence') {
      return premiumCollectionMonthlyProps({ premiumCollection });
    }
    return premiumCollectionDebtProps({ premiumCollection });
  };

  useEffect(handleOpenEmailModal, [premiumCollection]);

  return (
    <>
      <BtnTooltip
        icon="mail"
        iconSize="sm"
        variant="transparent"
        className="black"
        tooltipText="Enviar correo"
        onClick={handleGetPaymentData}
      />

      {premiumCollection && (
        <SendEmailModal {...getSendEmailProps()} extraCloseState={() => setPremiumCollection(null)} />
      )}
    </>
  );
};

export default SendEmailBtn;
