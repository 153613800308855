import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { SimpleCenteredModal } from '../../../components';
import { sendAlert } from '../../../actions/utils';
import { ModalBodyIcon } from '../../../components/Shared';
import AdminCommissionBulkUploadForm from './form/AdminCommissionBulkUploadForm';
import CommissionTextDescription from './form/CommissionTextDescription';
import PaymentTextDescription from './form/PaymentTextDescription';
import { adminBuklUploadCommissionRequest } from '../../../requests/admin/adminPremiumCollections';

const AdminBulkUploadCommissionNew = ({ modalShow, handleModalClose, setMoreData, uploadType }) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [modalConfirmationBody, setModalConfirmationBody] = useState('');
  const [modalConfirmationShow, setModalConfirmationShow] = useState(false);

  const handleSuccessRequest = response => {
    setErrorMessage(response.data.join(', '));
    if (response.data.length > 0) return;

    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Cobranza comisiones actualizada con éxito' }));
    setMoreData(m => !m);
  };

  const handleAdditionsRequest = (values, setSubmitting) => {
    setModalConfirmationShow(false);
    adminBuklUploadCommissionRequest({
      dispatch,
      params: snakeCaseKeys(values.premiumCollection),
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  const handleModalConfirmation = (values, setSubmitting) => {
    setModalConfirmationShow(true);
    setModalConfirmationBody(
      <ModalBodyIcon
        icon="reorder"
        iconVariant="danger"
        iconClass="text-dark"
        submitVariant="strong-danger"
        content="Estás cargando el listado de"
        highlightedText="comisiones informadas"
        highlightedVariant="light-danger"
        formRequest={() => handleAdditionsRequest(values, setSubmitting)}
        handleModalClose={() => {
          setModalConfirmationShow(false);
          setSubmitting(false);
        }}
      />
    );
  };

  return (
    <>
      {uploadType === 'commission' ? (
        <SimpleCenteredModal
          title="Carga masiva de comisión informada"
          body={
            <AdminCommissionBulkUploadForm
              textDescription={<CommissionTextDescription />}
              textAreaName="premiumCollection[reportedCommission]"
              placeholder={`N° póliza; Nº endoso (si corresponde); Comisión Póliza Informada Total Neta CLP$; Número de factura a la Compañía; Fecha de facturación; Mes de cobertura (si corresponde a cadencia mensual)\n...`}
              premiumCollection={{ associatedPayments: '' }}
              formRequest={handleModalConfirmation}
              handleModalClose={handleModalClose}
              errorMessage={errorMessage}
              formType="commission"
            />
          }
          show={modalShow}
          onHide={handleModalClose}
          size="xl"
        />
      ) : (
        <SimpleCenteredModal
          title="Carga masiva de pagos a asociados"
          body={
            <AdminCommissionBulkUploadForm
              textDescription={<PaymentTextDescription />}
              textAreaName="premiumCollection[associatedPayments]"
              placeholder={`N° póliza; Nº endoso (si corresponde); Número de factura del Asociado; Fecha de facturación; Mes de cobertura (si corresponde a cadencia mensual)\n...`}
              premiumCollection={{ reportedCommission: '' }}
              formRequest={handleModalConfirmation}
              handleModalClose={handleModalClose}
              errorMessage={errorMessage}
              formType="payment"
            />
          }
          show={modalShow}
          onHide={handleModalClose}
          size="xl"
        />
      )}

      <SimpleCenteredModal
        show={modalConfirmationShow}
        body={modalConfirmationBody}
        onHide={() => null}
        closeButton={false}
      />
    </>
  );
};

export default AdminBulkUploadCommissionNew;
