const generatePrompt = ({ insuranceCategoryName, validateAiPrompt, additionalInstruction }) => {
  return `
  En la póliza del ramo ${insuranceCategoryName}.
  Necesito verificar si cada uno de los siguientes puntos está presente en la póliza y si cumple con los montos mínimos indicados.

  Por favor, verifica los siguientes puntos en la póliza de seguro y responde de la siguiente manera:

    1. Responder con "símbolo de ticket" (✅) si el punto está presente en la póliza y el monto señalado es igual o superior al solicitado.
    2. Responder con "símbolo de cruz" (❌) si el punto no está presente o el monto indicado es inferior al solicitado. En este caso, proporcionar una breve explicación del motivo del incumplimiento.

  Entrega los resultados en formato JSON con la siguiente estructura:

  \`\`\`
  {
    "items": [
      { title: "punto 1", result: "resultado del punto 1 (✅ o ❌) - explicación del punto 1", is_valid: 'true' si es válido, 'false' si no es válido },
      { title: "punto 2", result: "resultado del punto 2 (✅ o ❌) - explicación del punto 2", is_valid: 'true' si es válido, 'false' si no es válido },
      { title: "punto 3", result: "resultado del punto 3 (✅ o ❌) - explicación del punto 3", is_valid: 'true' si es válido, 'false' si no es válido },
      // ... siguientes puntos
    ]
  }
  \`\`\`

  ## Los puntos a verificar que están presentes en la póliza son:
  ${validateAiPrompt}

  ## Especificaciones clave del análisis:

  - La respuesta debe limitarse a los puntos solicitados y no incluir referencias al documento original.
  - No incluyas ninguna información adicional más allá del JSON especificado.
  - Retorna exclusivamente el JSON.
  - No incluyas notas al pie, explicaciones adicionales, ni referencias al documento original.
  - Asegúrate de respetar estrictamente la estructura indicada para el JSON.

  ## Instrucción adicional:
    ${additionalInstruction || 'Ninguna'}
  `;
};

// eslint-disable-next-line import/prefer-default-export
export { generatePrompt };
