import React from 'react';
// import { getIn, useFormikContext } from 'formik';
// import camelCaseRecursive from 'camelcase-keys-recursive';
import { InputRemoteSelect } from '../../Utils/Select';
import { useFetchForRemoteSelect } from '../../../hooks';
import {
  debounceIndexInsuranceCategoryRequest,
  indexInsuranceCategoryRequest
} from '../../../requests/insuranceCategories';

const InsuranceCategorySelect = ({ selectedInsuranceType, disabled, ...props }) => {
  const { selectedInsuranceCategory, setSelectedInsuranceCategory } = props;
  const {
    options: insuranceCategories,
    selectedOption: selectedCategoryOption,
    fetchOptions: fetchInsuranceCategories
  } = useFetchForRemoteSelect({
    indexRequest: indexInsuranceCategoryRequest,
    debouncedIndexRequest: debounceIndexInsuranceCategoryRequest,
    value: selectedInsuranceCategory?.value,
    initialParams: {
      category_type: selectedInsuranceType || '',
      for_selector: false
    },
    nestedDependency: selectedInsuranceType || ''
  });

  return (
    <InputRemoteSelect
      abbr
      isClearable
      label="Ramo"
      placeholder="Ramo"
      defaultOptions={insuranceCategories}
      value={selectedCategoryOption}
      request={fetchInsuranceCategories}
      disabled={disabled}
      onChange={data => {
        setSelectedInsuranceCategory(data);
      }}
    />
  );
};

export default InsuranceCategorySelect;
