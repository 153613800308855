import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const HistoricalRecordsShow = ({ customClassName, historicalRecords, withTitle, baseUrl, linkField }) => {
  return (
    <div className={`historical-record ${withTitle ? 'with-title' : ''}`}>
      {withTitle && (
        <header className="historical-record__title">
          <p className="section-title capitalize-text">Historial</p>
        </header>
      )}
      {historicalRecords.length ? (
        <div className={`historical-record__scrollable ${customClassName}`}>
          {historicalRecords.map(record => {
            const { title, createdAt, id } = record;
            const content = (
              <>
                <p className="font-weight-500 historical-record--item__text">{title}</p>
                <p className="font-weight-500 historical-record--item__date">{createdAt}</p>
              </>
            );

            return (
              <>
                {baseUrl && linkField ? (
                  <Link
                    to={`${baseUrl}/${record[linkField]}`}
                    key={id}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    className="historical-record--item rounded-border"
                  >
                    {content}
                  </Link>
                ) : (
                  <div key={id} className="historical-record--item rounded-border">
                    {content}
                  </div>
                )}
              </>
            );
          })}
        </div>
      ) : (
        <div className="historical-record--empty-container">
          <p>No hay registros asociados</p>
        </div>
      )}
    </div>
  );
};

HistoricalRecordsShow.propTypes = {
  customClassName: PropTypes.string,
  withTitle: PropTypes.bool,
  baseUrl: PropTypes.string,
  linkField: PropTypes.string
};

HistoricalRecordsShow.defaultProps = {
  customClassName: '',
  withTitle: false,
  baseUrl: '',
  linkField: ''
};

export default HistoricalRecordsShow;
