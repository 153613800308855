import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Prompt } from 'react-router-dom';
import { withFormik, Form, getIn } from 'formik';

import { IconBtn, SimpleCenteredModal } from '../../components';
import { ModalBodyIcon, StepMap } from '../../components/Shared';
import InsurancePolicyProposalForm from '../../components/InsurancePolicies/Forms/InsurancePolicyProposalForm';
import basicInsuranceItem from '../../components/InsurancePolicies/Forms/basicInsuranceItem';
import { formatCurrency } from '../../services/utils';
import {
  InsurancePolicyFormFirstTab,
  InsurancePolicyFormSecondTab,
  InsurancePolicyFormThirdTab,
  InsurancePolicyFormSummary
} from './InsurancePoliciesForms';
import {
  firstStepFields,
  parsedInsurancePolicySchema,
  secondStepFields,
  thirdStepFields
} from './InsurancePoliciesForms/insurancePolicyFormValidations';

const InsurancePolicyForm = ({
  errors,
  isSubmitting,
  proposalOptions,
  setFieldTouched,
  setFieldValue,
  setSubmitting,
  values,
  disableNicoCode = false,
  fromLeadProposal,
  leadPresent,
  userModule,
  setInsurancePolicy,
  ...props
}) => {
  const modelName = 'insurancePolicy';

  const { action, fromAdmin, handleSubmit, modelUrl } = props;

  const {
    brokerCommissionAmount,
    brokerCommissionPercentage,
    commissionTotalAmount,
    contractFile,
    contractNumber,
    currency,
    insuranceEndorsementsAttributes,
    insuranceItemsAttributes,
    nicoCommissionPercentage
  } = values.insurancePolicy;

  const { isProposal = false, isRenewal = false, modalShow = false, setModalShow = () => null } = proposalOptions || {};

  const [disabledForm, setDisabledForm] = useState(false);
  const [step, setStep] = useState(1);
  const [temporalDropzoneFiles, setTemporalDropzoneFiles] = useState([]);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [useAi, setUseAi] = useState(false);
  const [showAdditionalContacts, setShowAdditionalContacts] = useState(false);
  const [showAdditionalCollectionContacts, setShowAdditionalCollectionContacts] = useState(false);

  const createContractFromProposal = isProposal && contractNumber && contractFile;
  const proposalSubmitText = createContractFromProposal ? 'Propuesta y Póliza' : 'Propuesta';
  const submitBtnText = action === 'new' ? `Crear ${isProposal ? proposalSubmitText : 'Póliza'}` : 'Guardar Cambios';
  const handleDisabledForm = isProposal ? disabledForm : isSubmitting;

  const updatedFirstStepFields = fromAdmin ? [...firstStepFields, 'executiveManagerId'] : firstStepFields;

  const checkStep = stepFields => {
    const stepErrors = getIn(errors, modelName);
    if (!stepErrors) return true;
    const result = stepFields.filter(value => stepErrors[value]);
    return result.length === 0;
  };

  const checkFirstStep = () => {
    return checkStep(updatedFirstStepFields);
  };

  const checkSecondStep = () => {
    return checkStep(secondStepFields);
  };

  const checkThirdStep = () => {
    return checkStep(thirdStepFields);
  };

  const firstStepTouch = () => {
    updatedFirstStepFields.forEach(field => setFieldTouched(`${modelName}[${field}]`, true));
  };

  const secondStepTouch = () => {
    secondStepFields.forEach(field => setFieldTouched(`${modelName}[${field}]`, true));
    insuranceItemsAttributes.forEach((insuranceItem, itemIndex) => {
      const insuranceItemPath = `${modelName}[insuranceItemsAttributes][${itemIndex}]`;

      const itemAttributes = ['name', 'notes'];
      itemAttributes.forEach(attribute => setFieldTouched(`${insuranceItemPath}[${attribute}]`, true));

      const coverAttributes = ['name', 'amount', 'fee', 'netPremium', 'coverageType'];
      insuranceItem.insuranceCoversAttributes.forEach((_, coverIndex) => {
        coverAttributes.forEach(attribute =>
          setFieldTouched(`${insuranceItemPath}[insuranceCoversAttributes][${coverIndex}][${attribute}]`, true)
        );
      });
    });
  };

  const thirdStepTouch = () => {
    thirdStepFields.forEach(field => setFieldTouched(`${modelName}[${field}]`, true));
    insuranceEndorsementsAttributes.forEach((_, index) => {
      setFieldTouched(`${modelName}[insuranceEndorsementsAttributes][${index}][endorsement]`, true);
    });
  };

  const validStep = stepToValidate => {
    const validations = { 1: checkFirstStep, 2: checkSecondStep, 3: checkThirdStep };
    const showErrors = { 1: firstStepTouch, 2: secondStepTouch, 3: thirdStepTouch };
    const validation = validations[stepToValidate];
    if (validation) {
      if (!validation()) {
        const showError = showErrors[stepToValidate];
        showError();
        return false;
      }
    }
    return true;
  };

  const toggleStep = ({ back = false }) => {
    let steps = { 0: 1, 1: 2, 2: 3 };
    const limitStep = back ? 0 : 4;
    if (back) {
      steps = { 4: 3, 3: 2, 2: 1 };
      setStep(steps[step] || limitStep);
    }

    if (validStep(step)) {
      setStep(steps[step] || limitStep);
    }
  };

  const handleOnSubmit = () => {
    setSubmitting(true);
    handleSubmit();
  };

  const handleFormSubmitType = () => {
    if (isProposal) {
      setModalShow(true);
      setDisabledForm(true);
    } else handleOnSubmit();
  };

  const handleOnCloseModal = () => {
    setModalShow(false);
    setSubmitting(false);
    setDisabledForm(false);
  };

  const handleComissions = () => {
    const companyCommissionPercentage = 100 - brokerCommissionPercentage;
    const baseCommissionPercentage = 100 - nicoCommissionPercentage;
    const nicoCommissionAmount = commissionTotalAmount * (nicoCommissionPercentage / 100);
    const companyCommissionAmount = commissionTotalAmount - nicoCommissionAmount - brokerCommissionAmount;
    setFieldValue(
      `${modelName}[parsedCompanyCommissionPercentage]`,
      formatCurrency({ value: companyCommissionPercentage, addon: '%' })
    );
    setFieldValue(
      `${modelName}[parsedBaseCommissionPercentage]`,
      formatCurrency({ value: baseCommissionPercentage, addon: '%' })
    );
    setFieldValue(
      `${modelName}[parsedCompanyCommissionAmount]`,
      formatCurrency({ value: companyCommissionAmount, addon: ` ${currency}` })
    );
    setFieldValue(
      `${modelName}[parsedBrokerCommissionAmount]`,
      formatCurrency({ value: brokerCommissionAmount, addon: ` ${currency}` })
    );
    setFieldValue(
      `${modelName}[parsedNicoCommissionAmount]`,
      formatCurrency({ value: nicoCommissionAmount, addon: ` ${currency}` })
    );
  };

  const handleAllowValueUpdate = () => {
    if ([2, 3, 4].includes(step) && allowUpdate) {
      setAllowUpdate(false);
    }
  };

  useEffect(handleAllowValueUpdate, [step]);
  useEffect(handleComissions, [
    brokerCommissionPercentage,
    nicoCommissionPercentage,
    commissionTotalAmount,
    brokerCommissionAmount
  ]);

  return (
    <>
      <Prompt when={!isSubmitting} message="¿Seguro deseas abandonar? Toda la información será perdida" />

      <Form>
        <StepMap steps={[1, 2, 3, 4]} currentStep={step} />

        {step === 1 && (
          <InsurancePolicyFormFirstTab
            userModule={userModule}
            allowUpdate={allowUpdate}
            setAllowUpdate={setAllowUpdate}
            fromAdmin={fromAdmin}
            modelName={modelName}
            isProposal={isProposal}
            fromLeadProposal={fromLeadProposal}
            setInsurancePolicy={setInsurancePolicy}
            leadPresent={leadPresent}
            setUseAi={setUseAi}
            showAdditionalContacts={showAdditionalContacts}
            showAdditionalCollectionContacts={showAdditionalCollectionContacts}
            setShowAdditionalContacts={setShowAdditionalContacts}
            setShowAdditionalCollectionContacts={setShowAdditionalCollectionContacts}
            action={action}
          />
        )}
        {step === 2 && (
          <InsurancePolicyFormSecondTab
            userModule={userModule}
            action={action}
            fromAdmin={fromAdmin}
            modelName={modelName}
            isProposal={isProposal}
            fromLeadProposal={fromLeadProposal}
            leadPresent={leadPresent}
            isRenewal={isRenewal}
            useAi={useAi}
          />
        )}
        {step === 3 && (
          <InsurancePolicyFormThirdTab
            modelName={modelName}
            temporalDropzoneFiles={temporalDropzoneFiles}
            setTemporalDropzoneFiles={setTemporalDropzoneFiles}
            isProposal={isProposal}
            disableNicoCode={disableNicoCode}
            userModule={userModule}
          />
        )}
        {step === 4 && (
          <InsurancePolicyFormSummary
            isRenewal={isRenewal}
            action={action}
            modelName={modelName}
            isProposal={isProposal}
          />
        )}

        <Row>
          <Col md={4} lg={3} className="mt-4">
            {step > 1 && (
              <Button block variant="cancel" onClick={() => toggleStep({ back: true })}>
                Volver
              </Button>
            )}
          </Col>
          <Col md={8} lg={9}>
            <Row className="d-flex flex-row-reverse align-items-center">
              <Col md={6} lg={4} className="mt-4">
                {step === 4 ? (
                  <Button block variant="submit" onClick={handleFormSubmitType} disabled={handleDisabledForm}>
                    {submitBtnText}
                  </Button>
                ) : (
                  <Button block variant="submit" onClick={toggleStep}>
                    Siguiente
                  </Button>
                )}
              </Col>
              <Col md={5} lg={3} className="mt-4">
                <IconBtn block variant="cancel" to={modelUrl}>
                  Cancelar
                </IconBtn>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <SimpleCenteredModal
        show={modalShow}
        title={createContractFromProposal ? '' : 'Documento de propuesta'}
        body={
          createContractFromProposal ? (
            <ModalBodyIcon
              icon="notes"
              iconVariant="danger"
              iconClass="text-dark"
              submitVariant="strong-danger"
              content="Creación de"
              subContent="Recuerda siempre revisar que los datos de la póliza representen lo solicitado en la propuesta."
              confirmText
              confirmContent="¿estás seguro de querer crear la póliza directamente?"
              highlightedText="póliza"
              highlightedVariant="light-danger"
              formRequest={handleOnSubmit}
              handleModalClose={handleOnCloseModal}
              isSubmitting={isSubmitting}
            />
          ) : (
            <InsurancePolicyProposalForm
              isRenewal={isRenewal}
              insurancePolicy={values.insurancePolicy}
              formRequest={handleOnSubmit}
              onHide={handleOnCloseModal}
              isSubmitting={isSubmitting}
            />
          )
        }
        onHide={handleOnCloseModal}
      />
    </>
  );
};

const setInitialValues = ({ insurancePolicy, proposalOptions }) => {
  const {
    acceptanceInfo,
    additionalDocumentsInfo,
    contractInfo,
    contractProposalInfo,
    insuranceEndorsementsAttributes,
    insuranceItemsAttributes,
    quotationInfo,
    selectedLeadQuotation
  } = insurancePolicy;

  const { isProposal = false } = proposalOptions || {};

  const zeroItems = insuranceItemsAttributes.length === 0;
  const insuranceItems = zeroItems ? [basicInsuranceItem] : insuranceItemsAttributes;

  const insuranceEndorsements = insuranceEndorsementsAttributes.map(insuranceEndorsement => {
    return { ...insuranceEndorsement, endorsement: getIn(insuranceEndorsement.endorsementInfo, 'fileSignature') };
  });

  let files = {};
  if (isProposal) {
    const fileAcceptance = selectedLeadQuotation ? selectedLeadQuotation.acceptance : acceptanceInfo;
    const fileQuotation = selectedLeadQuotation ? selectedLeadQuotation.quotation : quotationInfo;
    files = {
      acceptance: getIn(fileAcceptance, 'fileSignature'),
      acceptanceInfo: fileAcceptance,
      quotation: getIn(fileQuotation, 'fileSignature'),
      quotationInfo: fileQuotation
    };
  } else {
    files = {
      contract: getIn(contractInfo, 'fileSignature'),
      contractProposal: getIn(contractProposalInfo, 'fileSignature')
    };
  }

  files = { ...files, additionalDocuments: additionalDocumentsInfo.map(file => getIn(file, 'fileSignature')) };

  return {
    insurancePolicy: {
      ...insurancePolicy,
      ...files,
      insuranceEndorsementsAttributes: insuranceEndorsements,
      insuranceItemsAttributes: insuranceItems
    }
  };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema: parsedInsurancePolicySchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new',
  validateOnChange: false
})(InsurancePolicyForm);
