import { handleDateFormat } from '../../../services/utils';
import infoCardElement from '../../Utils/Cards/InfoCardMethods';

const generalItemObject = ({ contractItem, userModule }) => {
  const {
    itemNumber,
    name,
    notes,
    insurancePolicyId,
    policyItemNumber,
    translatedStatus,
    insurancePolicyHistories = [],
    insurancePolicyInfo = {}
  } = contractItem;

  const {
    insuranceBrokerCompanyName,
    insuranceCategoryName,
    insuranceCompanyName,
    validityEnd,
    validityStart,
    renewablePolicyNumber
  } = insurancePolicyInfo;

  const object = {};

  const policyUrl = userModule === 'admin' ? '/admin/insurance_policies' : '/insurance_policies';

  object.contractNumber = infoCardElement({
    string: true,
    key: 'N° de bien',
    value: itemNumber
  });
  object.description = infoCardElement({
    string: true,
    key: 'Nombre del bien',
    value: name
  });
  object.status = infoCardElement({
    string: true,
    key: 'Estado bien',
    value: translatedStatus || 'Sin definir'
  });
  object.notes = infoCardElement({
    string: true,
    key: 'Notas',
    value: notes || 'Sin notas'
  });
  object.policyNumberAssociated = infoCardElement({
    link: true,
    key: 'N° Póliza asociada',
    value: insurancePolicyInfo.policyNumber,
    url: `${policyUrl}/${insurancePolicyId}`
  });
  object.policyItemNumber = infoCardElement({
    string: true,
    key: 'N° de Ítem en Póliza',
    value: policyItemNumber
  });

  if (insurancePolicyHistories.length > 0) {
    object.policyItemHistory = infoCardElement({
      arrayCommaSeparated: true,
      key: 'Historial de pólizas asociadas',
      items: insurancePolicyHistories.map(({ insurancePolicyId: policyId, policyNumber }) =>
        infoCardElement({
          link: true,
          commaSeparated: true,
          subelement: true,
          value: policyNumber,
          url: `${policyUrl}/${policyId}`
        })
      )
    });
  }

  if (Object.keys(insurancePolicyInfo).length > 0) {
    object.insuranceCompanyName = infoCardElement({
      string: true,
      key: 'Aseguradora',
      value: insuranceCompanyName
    });
    object.insuranceBrokerCompanyName = infoCardElement({
      string: true,
      key: 'Corredor',
      value: insuranceBrokerCompanyName
    });
    object.insuranceCategoryName = infoCardElement({
      string: true,
      key: 'Ramo',
      value: insuranceCategoryName
    });
    object.validityStart = infoCardElement({
      string: true,
      key: 'Inicio de vigencia póliza',
      value: handleDateFormat(validityStart)
    });
    object.validityEnd = infoCardElement({
      string: true,
      key: 'Término de vigencia póliza',
      value: handleDateFormat(validityEnd)
    });
    if (renewablePolicyNumber)
      object.renewablePolicyNumber = infoCardElement({
        string: true,
        key: 'N° Póliza anterior',
        value: renewablePolicyNumber
      });
  }

  return object;
};

export default generalItemObject;
