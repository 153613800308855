import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { InputRemoteSelect, UploadFile } from '../../../../components';
import { useFetchForRemoteSelect } from '../../../../hooks';
import {
  debounceIndexInsuranceBrokeresRequest,
  indexInsuranceBrokerRequest
} from '../../../../requests/admin/insuranceBrokers';
import { InfoBox } from '../../../../components/Shared';

const ContractItemForm = ({ formRequest, ...props }) => {
  const { handleModalClose, errorMessage } = props;
  const commonClasses = 'bg-light-gray px-3 rounded-pill font-weight-bold';
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedInsuranceBroker, setSelectedInsuranceBroker] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const { options, selectedOption, fetchOptions: fetchInsuranceBrokers } = useFetchForRemoteSelect({
    indexRequest: indexInsuranceBrokerRequest,
    debouncedIndexRequest: debounceIndexInsuranceBrokeresRequest,
    value: selectedInsuranceBroker?.value
  });

  const reportMessage = () => {
    return (
      <>
        <p className="mb-1">Reporte:</p>

        {errorMessage.errors.map((message, index) => (
          <p key={`message-${index.toString()}`}>- {message}</p>
        ))}

        <p className="mt-1">Importaciones exitosas: {errorMessage?.success_items}</p>
        <p>Importaciones rechazadas: {errorMessage?.failure_items}</p>
      </>
    );
  };

  return (
    <>
      {errorMessage && (
        <>
          <InfoBox icon="danger" variant="danger" htmlContent={reportMessage()} />
        </>
      )}
      <ul>
        <li className="ml-4">
          Adjunta un documento <span className={commonClasses}>XLSX</span>
        </li>
      </ul>

      <p>
        Ejemplo:
        <a href="/Carga_bienes.xlsx" download className="ml-2">
          Carga_bienes.xlsx
        </a>
      </p>

      <Row>
        <Col xs={12}>
          <UploadFile
            abbr
            fileAccept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            // label="Bienes"
            onChange={file => setSelectedFile(file)}
          />
        </Col>

        <Col md={12}>
          <InputRemoteSelect
            abbr
            isClearable
            label="Corredor"
            placeholder="Seleccionar corredor"
            defaultOptions={options}
            value={selectedOption}
            request={fetchInsuranceBrokers}
            onChange={data => {
              setSelectedInsuranceBroker(data);
            }}
          />
        </Col>
      </Row>

      <Row className="mt-2 d-flex justify-content-end">
        <Col md={7}>
          <Row>
            <Col md={6} className="mt-4">
              <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
                Cancelar
              </Button>
            </Col>
            <Col md={6} className="mt-4">
              <Button
                block
                variant="submit"
                onClick={() => formRequest(selectedFile, setSubmitting, selectedInsuranceBroker?.value)}
                className="no-shadow"
                disabled={!selectedFile || !selectedInsuranceBroker || submitting}
              >
                {submitting ? 'Cargando...' : 'Cargar'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ContractItemForm;
