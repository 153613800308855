import React, { useLayoutEffect, useState } from 'react';
import { useAbility } from '@casl/react';
import { useSelector, useDispatch } from 'react-redux';

import snakecaseKeys from 'snakecase-keys';
import { AutoSaveCommentsForm, HistoricalRecordsShow } from '../../components';
import { AttachedFile, SwitchButton } from '../../components/InsurancePolicies';
import { InfoCardAdditionalDocument } from '../../components/Shared';
import sendDocumentEmail from '../../components/Shared/SendDocumentEmail';
import { insurancePolicyProps, leadProps } from '../../components/Shared/SendEmailModalProps';
import { InfoCardEndorsement, InfoCardGeneral, InfoCardMonetary, InfoCardValidity } from './InfoCard';
import basicInfoRoles from './basicInfoRoles';
import { AbilityContext } from '../../config/abilityContext';
import useModal from '../../hooks/useModal';
import ValidateContentAiModal from '../../components/InsurancePolicies/ValidateContentAi/ValidateContentAiModal';
import useAiParameters from './InsurancePoliciesForms/useAiParameters';
import ValidationSwitchButton from '../../components/InsurancePolicies/ValidationSwitchButton';

const InsurancePolicyInfo = ({ insurancePolicy, setMoreData, userModule, modelUrl }) => {
  const modelName = 'insurancePolicy';
  const dispatch = useDispatch();
  const [lead, setLead] = useState({});

  // GET CURRENT ROLE DATA
  const currentUserOptions = basicInfoRoles[userModule];
  const { can, debounceUpdateRequest, hideAttributes, isAdmin, updateRequest } = currentUserOptions;
  const ability = useAbility(AbilityContext);
  const { user: currentUser } = useSelector(state => state.auth);

  const {
    id,
    account = {},
    acceptanceInfo = {},
    additionalDocumentsInfo,
    adminComments,
    aiValidated,
    comments,
    contractInfo = {},
    contractProposalInfo = {},
    debtInstallment,
    debtStartDate,
    historicalRecords = [],
    insuranceEndorsementsAttributes,
    insuranceCategoryId,
    insuranceBrokerCompanyId,
    isInvalid,
    isDocumentAnalyzed,
    lastValidatedAt,
    nicoCode,
    parsedDebtAmount,
    policyType,
    quotationInfo = {},
    withDebt,
    withSinister,
    validateAiPrompt
  } = insurancePolicy;

  const isContract = policyType === 'contract';
  const hasAnyInsuranceEndorsement = insuranceEndorsementsAttributes.length > 0;
  const hasContractProposal = Object.keys(lead).length > 0 && Object.keys(contractProposalInfo).length > 0;

  let debtCardText = withDebt ? `Fecha de inicio: ${debtStartDate}` : '';
  if (parsedDebtAmount) debtCardText += `\nMonto deuda: ${parsedDebtAmount}`;
  if (debtInstallment) debtCardText += `\nNúmero cuota: ${debtInstallment}`;
  const { aiParameters, validateAiParamsCount } = useAiParameters(insuranceCategoryId, insuranceBrokerCompanyId);
  const disabledValidateAiButton = () => {
    return (validateAiParamsCount === 0 && !validateAiPrompt) || !contractInfo;
  };

  const { ModalComponent: ValidateAiModalComponent, handleModalShow, handleModalClose } = useModal();
  const handleCompletedStatus = params => {
    const filteredParams = Object.fromEntries(Object.entries(params).filter(([, value]) => value !== null));

    updateRequest(id, {
      dispatch,
      params: snakecaseKeys(filteredParams),
      callback: () => {
        handleModalClose();
        setMoreData(m => !m);
      }
    });
  };
  const handleValidateContentAi = () => {
    handleModalShow({
      body: (
        <ValidateContentAiModal
          handleModalClose={handleModalClose}
          policyFile={contractInfo.url}
          attachmentId={contractInfo?.id}
          insuranceCategoryId={insuranceCategoryId}
          insurancePolicy={insurancePolicy}
          companyId={insuranceBrokerCompanyId}
          validateAiPrompt={validateAiPrompt}
          modelName={modelName}
          options={aiParameters}
          onCompletedStatus={handleCompletedStatus}
          userModule={userModule}
        />
      )
    });
  };

  const { SendEmailModal: SendContractEmailModal, openEmailModal: openContractEmailModal } = sendDocumentEmail({
    fromAdmin: isAdmin,
    executiveModel: { id: insurancePolicy.id, name: 'InsurancePolicy' },
    showCcField: true,
    textCancelButton: 'Cerrar',
    fileSection: true,
    fileSectionLabel: 'Adjuntos'
  });

  const { SendEmailModal: SendProposalEmailModal, openEmailModal: openProposalEmailModal } = sendDocumentEmail({
    fromAdmin: isAdmin,
    executiveModel: { id: insurancePolicy.id, name: 'InsurancePolicy' },
    showCcField: true,
    textCancelButton: 'Cerrar',
    fileSection: true,
    fileSectionLabel: 'Adjuntos'
  });

  const handleLeadProps = () => {
    setLead({ isRenewal: false, insurancePolicyProposal: insurancePolicy });
  };

  const isReadOnly = () => {
    return isAdmin ? false : isInvalid || !can.editSwitchBtn;
  };

  const isReadOnlyDebtSwitch = () => {
    const isEditable = can.editSwitchBtn && nicoCode === 'ext';
    return !isAdmin && (isInvalid || !isEditable);
  };

  const canManageEndorsement = () => {
    if (userModule === 'insured' || userModule === 'broker') {
      return (ability.can('manage', 'Admin') || ability.can('manage', 'InsuranceEndorsement')) && nicoCode === 'ext';
    }

    return ability.can('manage', 'Admin');
  };

  const debtSwitchBtnIcon = () => {
    const variant = isReadOnlyDebtSwitch() ? 'disabled' : 'danger';
    return { name: 'dollar', variant };
  };

  const debtSwitchCardVariant = () => {
    const variant = isReadOnlyDebtSwitch() ? 'disabled' : 'danger';
    return withDebt ? variant : '';
  };

  const isInsuredEmailPresent = email => {
    const { contactsAttributes = [] } = account;
    if (contactsAttributes.length === 0) return false;

    const normalizedEmail = email.trim().toLowerCase();

    return contactsAttributes.some(
      contact =>
        contact.isInsuredUser === true &&
        typeof contact.email === 'string' &&
        contact.email.trim().toLowerCase() === normalizedEmail
    );
  };

  const canManageAccount = () => {
    return ability.can('manage', 'Admin') || ability.can('manage', 'Account');
  };

  const showAccountLink = () => {
    if (userModule === 'insured') {
      return canManageAccount() && isInsuredEmailPresent(currentUser.email);
    }

    return canManageAccount();
  };

  useLayoutEffect(handleLeadProps, [insurancePolicy]);

  return (
    <>
      <div className="insurance-policy-show-layout main">
        <div className="general-information">
          <InfoCardGeneral
            userModule={userModule}
            insurancePolicy={insurancePolicy}
            hideAttributes={hideAttributes.general}
            showAccountLink={showAccountLink()}
          />
          {hasAnyInsuranceEndorsement && (
            <InfoCardEndorsement
              fromAdmin={isAdmin}
              canManageEndorsement={canManageEndorsement()}
              insurancePolicy={insurancePolicy}
              canEditEndorsements={can.editEndorsement || canManageEndorsement()}
              setMoreData={setMoreData}
              userModule={userModule}
            />
          )}
          <InfoCardAdditionalDocument
            modelName={modelName}
            modelUrl={modelUrl}
            object={insurancePolicy}
            additionalDocuments={additionalDocumentsInfo}
            setMoreData={setMoreData}
            updateRequest={updateRequest}
            canAddDocuments={can.addAdditionalDocument}
          />
        </div>
        <div className="details-information">
          <InfoCardValidity insurancePolicy={insurancePolicy} hideAttributes={hideAttributes.validity} />
          <InfoCardMonetary insurancePolicy={insurancePolicy} hideAttributes={hideAttributes.monetary} />
        </div>
        <div className="side-components">
          {isContract ? (
            <>
              <SwitchButton
                id={id}
                title="Siniestro"
                attribute="with_sinister"
                icon={{ name: 'smile-sad', variant: 'danger' }}
                cardVariant={withSinister ? 'danger' : ''}
                updateRequest={updateRequest}
                initialValue={withSinister}
                readonly={isReadOnly()}
                setMoreData={setMoreData}
              />
              <SwitchButton
                id={id}
                title="Deuda"
                attribute="with_debt"
                subtitle={debtCardText}
                icon={debtSwitchBtnIcon()}
                cardVariant={debtSwitchCardVariant()}
                updateRequest={updateRequest}
                initialValue={withDebt}
                readonly={isReadOnlyDebtSwitch()}
                setMoreData={setMoreData}
              />
              {ability.can('manage', 'aiValidation') && (
                <ValidationSwitchButton
                  id={id}
                  isAnalyzed={isDocumentAnalyzed}
                  isValidated={aiValidated}
                  lastValidatedDate={lastValidatedAt}
                  updateRequest={updateRequest}
                  setMoreData={setMoreData}
                  onValidateContentAi={handleValidateContentAi}
                  disableValidateButton={disabledValidateAiButton()}
                  readonly={isReadOnly()}
                />
              )}
              <AttachedFile
                title="Póliza"
                attribute="contract"
                fileUrl={contractInfo.fileUrl}
                extraButton={
                  can.sendDocument
                    ? {
                        name: 'Enviar',
                        active: true,
                        disabled: !contractInfo.fileUrl,
                        onClick: openContractEmailModal
                      }
                    : false
                }
              />
              <AttachedFile
                title="Propuesta"
                attribute="contractProposal"
                fileUrl={contractProposalInfo.fileUrl}
                extraButton={
                  can.sendDocument
                    ? {
                        name: 'Enviar',
                        active: true,
                        disabled: !hasContractProposal,
                        onClick: openProposalEmailModal
                      }
                    : false
                }
              />
              <SendContractEmailModal {...insurancePolicyProps({ insurancePolicy })} />
              {hasContractProposal && <SendProposalEmailModal {...leadProps({ lead })} />}
            </>
          ) : (
            <>
              <AttachedFile title="Cotización" attribute="quotation" fileUrl={quotationInfo.fileUrl} />
              <AttachedFile title="Aprobación" attribute="acceptance" fileUrl={acceptanceInfo.fileUrl} />
            </>
          )}
        </div>
      </div>

      <div className={`insurance-policy-show-layout ${can.autoSaveComment ? 'secondary' : ''}`}>
        {can.autoSaveComment && (
          <AutoSaveCommentsForm
            row={7}
            withTitle
            resourceId={id.toString()}
            resourceComments={comments}
            resourcePath="insurance_policy[comments]"
            debouncedUpdateRequest={debounceUpdateRequest}
          />
        )}

        <HistoricalRecordsShow withTitle historicalRecords={historicalRecords} />
      </div>

      {can.autoSaveComment && isAdmin && (
        <div className="mt-5">
          <AutoSaveCommentsForm
            withTitle
            title="Nota admin"
            resourceId={id.toString()}
            resourceComments={adminComments}
            resourcePath="insurance_policy[admin_comments]"
            debouncedUpdateRequest={debounceUpdateRequest}
          />
        </div>
      )}
      <ValidateAiModalComponent size="xl" />
    </>
  );
};
export default InsurancePolicyInfo;
