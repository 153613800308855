import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import snakeCaseKeys from 'snakecase-keys';

import { ComponentDataTable } from '..';
import { conditionalRowStyles, customStyles } from '../Shared/DataTable';
import { emptyFalseParamsRecursive } from '../../services/utils';
import { insuranceContractFiltersParams } from './Filters/InsuranceContractFiltersParams';
import InsuranceContractFiltersHeader from './Filters/InsuranceContractFiltersHeader';
import { removeFilters } from '../../actions/utils';
import { useDestroyOne } from '../../hooks';
import { deleteAdminContractItemRequest } from '../../requests/admin/adminContractItems';
import { deleteContractItemRequest } from '../../requests/contractItems';

const InsuranceContractDataTable = ({
  columns,
  customParamsRef,
  defaultParams,
  FilterHeaderComponent,
  filterHeaderComponentProps = {},
  fromAdmin = false,
  handleIndexRequest,
  isFetching,
  modelUrl,
  moreData,
  noSubHeader,
  setMoreData,
  tableData,
  tableDataAmount,
  handleCheckboxChange,
  selectedItems,
  userModule
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [customParams, setCustomParams] = useState(
    filterHeaderComponentProps.filterParams || insuranceContractFiltersParams
  );

  const filtersSanitize = (params = {}) => {
    if (noSubHeader) return;
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(m => !m);
    dispatch(removeFilters());
  };

  const handleSortCase = name => {
    switch (name) {
      case 'contract_number':
        return { sort_contract_number: name };
      case 'item_number':
        return { sort_column: name };
      case 'name':
        return { sort_column: name };
      case 'account':
        return { sort_account: name };
      case 'policy_number':
        return { sort_policy_number: name };
      case 'insurance_broker_company_name':
        return { sort_insurance_broker_company: name };
      default:
        return { sort_priority: name };
    }
  };

  const handleRowClick = row => {
    const { id } = row;
    history.push(`${modelUrl}/${id}`);
  };

  const handleFilterRequest = () => filtersSanitize(customParams);

  const getDeleteItemRequest = () => {
    const requestMap = {
      admin: deleteAdminContractItemRequest,
      broker: deleteContractItemRequest
    };
    return requestMap[userModule];
  };

  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: getDeleteItemRequest(),
    modelName: 'Bien',
    nameEndsInA: false, // Ajusta según si el nombre del modelo en tu idioma termina en 'a'
    setMoreData
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  useEffect(handleFilterRequest, [customParams]);

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({ setMoreData, handleBasicActions, modelUrl, selectedItems, handleCheckboxChange })}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        customStyles={customStyles}
        handleSortCase={handleSortCase}
        onRowClicked={(row, e) => handleRowClick(row, e)}
        highlightOnHover
        conditionalRowStyles={conditionalRowStyles}
        customDatatableHeader={
          FilterHeaderComponent ? (
            <FilterHeaderComponent {...filterHeaderComponentProps} />
          ) : (
            <InsuranceContractFiltersHeader
              fromAdmin={fromAdmin}
              customParams={customParams}
              setCustomParams={setCustomParams}
              filterHeaderComponentProps={filterHeaderComponentProps}
            />
          )
        }
        pointerOnHover
        resourceRequest={handleIndexRequest}
        noSubHeader={noSubHeader}
        defaultParams={{ sortColumn: 'priority', ...defaultParams }}
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default InsuranceContractDataTable;
