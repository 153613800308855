const defaultPrompt = ({ companyName, categoryName, additionalInstruction }) => {
  return `
	- Analiza el siguiente documento de póliza de seguro de la compañía aseguradora "${companyName}", del ramo "${categoryName}".
	- Extrae los datos especificados y asigna cada dato a la llave JSON correspondiente que se indica. Luego, proporciona los datos extraídos en formato JSON.

	Datos a extraer y sus llaves correspondientes:

	\`\`\`
	{
			"policy_number": "Número de Póliza",
			"responsible_email": "Correo electrónico del contratante",
			"hiring_name": "Nombre del contratante",
			"hiring_national_identification": "Rut del contratante",
			"hiring_address": "Dirección del contratante",
			"insured_name": "Nombre del asegurado",
			"insured_national_identification": "RUT del asegurado",
			"insured_address": "Dirección del asegurado",
			"currency": "Moneda usada en la póliza (puede ser 'UF' o 'USD')",
			"validity_start": "Fecha de inicio de vigencia en formato dd/mm/yyyy",
			"validity_end": "Fecha de término de vigencia en formato dd/mm/yyyy",
			"issue_date": "Fecha de emisión en formato dd/mm/yyyy",
			"total_affect_premium": "Prima afecta total de la póliza en valor numérico.",
			"total_exepmt_premium": "Prima exenta total de la póliza en valor numérico.",
			"total_premium": "Prima total de la póliza en valor numérico.",
			"total_amount": "Monto asegurado total en valor numérico.",
			"general_description": "Descripción general de la materia a asegurada y las coberturas.",
			"affect_commission": "Porcentaje comisión afecta en valor numérico.",
			"exempt_commission": "Porcentaje comisión exenta en valor numérico.",
			"renewal_type": "Tipo de renovación (Puede ser renovable, no renovable o prorrogable).",
			"payment_plan": "Número de cuotas del plan de pago en valor numérico.",
			"items": [
					{
							"item_name": "Nombre del primer ítem en la póliza",
							"covers": [ // Simplifica todas las coberturas en una sola general.
									{
											"cover_detail": "Agregar el nombre del plan o convenio. Este viene especificado en la póliza normalmente",
											"cover_amount": "Monto total de la cobertura o monto asegurado en valor numérico",
											"fee": "Tasa de la cobertura en valor numérico",
											"net_premium": "Prima neta total de la cobertura en valor numérico",
											"coverage_type": "Tipo de prima o cobertura (afecta o exenta)",
											"deductible": "Valor del deducible de la cobertura en valor numérico."
									}
							]
					}
			// Repetir esta estructura para cada ítem de la póliza
    ]
  }
	\`\`\`

	- Los datos que no encuentres o que no estén especificados, déjalos como "null".
	- Los "items", corresponden a los ítems de la póliza de seguros. Una póliza puede tener más de 1 ítem.
	- Cada ítem, puede tener más de una cobertura. Las coberturas van en la llave "covers", dentro de cada "item".
	- Si un ítem tiene más de una cobertura, simplifica y resume las coberturas en una general.
	- Las fechas si o si deben estar en formato "dd/mm/yyyy".
	- Retorna sólo el JSON.
	- Extrae todos los ítems del documento, asegurándote de incluir todos los detalles de cada uno, sin omitir ninguno. 
		Cada ítem debe incluir sus coberturas y la información completa del vehículo asociado. Si el documento contiene 30 ítems, 
		quiero ver los 30 ítems en el resultado.

	Instrucciónes adicionales:

	${additionalInstruction || 'Ninguna.'}
	`;
};

const vehiclePrompt = ({ companyName, categoryName, additionalInstruction }) => {
  return `
	- Analiza el siguiente documento de póliza de seguro de la compañía aseguradora "${companyName}", del ramo "${categoryName}".
	- Extrae los datos especificados y asigna cada dato a la llave JSON correspondiente que se indica. Luego, proporciona los datos extraídos en formato JSON.

	Datos a extraer y sus llaves correspondientes:

	\`\`\`
	{
			"policy_number": "Número de Póliza",
			"responsible_email": "Correo electrónico del contratante",
			"hiring_name": "Nombre del contratante",
			"hiring_national_identification": "Rut del contratante",
			"hiring_address": "Dirección del contratante",
			"insured_name": "Nombre del asegurado",
			"insured_national_identification": "RUT del asegurado",
			"insured_address": "Dirección del asegurado",
			"currency": "Moneda usada en la póliza (puede ser 'UF' o 'USD')",
			"validity_start": "Fecha de inicio de vigencia en formato dd/mm/yyyy",
			"validity_end": "Fecha de término de vigencia en formato dd/mm/yyyy",
			"issue_date": "Fecha de emisión en formato dd/mm/yyyy",
			"total_affect_premium": "Prima afecta total de la póliza en valor numérico.",
			"total_exepmt_premium": "Prima exenta total de la póliza en valor numérico.",
			"total_premium": "Prima total de la póliza en valor numérico.",
			"total_amount": "Monto asegurado total en valor numérico.",
			"general_description": "Descripción general de la materia a asegurada y las coberturas.",
			"affect_commission": "Porcentaje comisión afecta en valor numérico.",
			"exempt_commission": "Porcentaje comisión exenta en valor numérico.",
			"renewal_type": "Tipo de renovación (Puede ser renovable, no renovable o prorrogable).",
			"payment_plan": "Número de cuotas del plan de pago en valor numérico.",
			"items": [
					{
							"item_name": "Nombre del primer ítem en la póliza",
							"covers": [ // Agrega la cobertura con el monto más alto entre todas las coberturas
									{
											"cover_detail": "Agregar el nombre del plan o convenio. Este viene especificado en la póliza normalmente",
											"cover_amount": "Monto de la cobertura o monto asegurado más alto entre todas las coberturas, en valor numérico",
											"fee": "Tasa de la cobertura en valor numérico",
											"net_premium": "Prima neta total de la cobertura en valor numérico",
											"coverage_type": "Tipo de prima o cobertura (afecta o exenta)"
											"deductible": "Valor del deducible de la cobertura en valor numérico."
									}
							],
							license_plate: "Patente o placa única del vehículo",
							brand: "Marca del vehículo",
							model: "Modelo del vehículo",
							year:  "Año del vehículo",
							use:  "Uso del vehículo (Particular o Comercial)"
							motor_number: "Número del motor del vehículo",
							chassis_number: "Número de chasis del vehículo"
							vehicle_type: "Tipo de vehículo (Liviano o Pesado)"
					}
			// Repetir esta estructura para cada ítem de la póliza
    ]
  }
	\`\`\`

	- Los datos que no encuentres o que no estén especificados, déjalos como "null".
	- Los "items", corresponden a los ítems de la póliza de seguros. Una póliza puede tener más de 1 ítem.
	- Cada ítem, puede tener más de una cobertura. Las coberturas van en la llave "covers", dentro de cada "item".
	- Si un ítem tiene más de una cobertura, agrega la cobertura con el monto más alto entre todas las coberturas.
	- Las fechas si o si deben estar en formato "dd/mm/yyyy".
	- Retorna sólo el JSON.
	- Extrae todos los ítems del documento, asegurándote de incluir todos los detalles de cada uno, sin omitir ninguno. 
		Cada ítem debe incluir sus coberturas y la información completa del vehículo asociado. Si el documento contiene 30 ítems, 
		quiero ver los 30 ítems en el resultado.

	Instrucciónes adicionales:

	${additionalInstruction || 'Ninguna'}
	`;
};

// eslint-disable-next-line import/prefer-default-export
export const generateReadPolicyPrompt = ({ companyName, categoryName, additionalInstruction }) => {
  const promptMap = {
    vehículos: vehiclePrompt({ companyName, categoryName, additionalInstruction }),
    default_prompt: defaultPrompt({ companyName, categoryName, additionalInstruction })
  };

  return promptMap[categoryName.toLowerCase()] || promptMap.default_prompt;
};
