import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const deletePremiumCollectionRequest = (id, { dispatch, successCallback, failureCallback, callback, source }) =>
  ApiService.request('delete', `/premium_collections/${id}`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const getPremiumCollectionPaymentDataRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/premium_collections/${id}/payment_data`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const headerDashboardPremiumCollectionRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/premium_collections/header_dashboard', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const indexCommissionCollectionRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/premium_collections', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const indexPremiumCollectionRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/premium_collections', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const sendReminderRequest = (
  id,
  { dispatch, params = {}, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('post', `/premium_collections/${id}/send_reminder`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updatePremiumCollectionRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/premium_collections/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const sendPaymentReminderRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/premium_collections/send_payment_reminder', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceHeaderDashboardPremiumCollectionRequest = AwesomeDebouncePromise(
  headerDashboardPremiumCollectionRequest,
  300
);

export const debounceIndexCommissionCollectionRequest = AwesomeDebouncePromise(indexCommissionCollectionRequest, 300);

export const debounceIndexPremiumCollectionRequest = AwesomeDebouncePromise(indexPremiumCollectionRequest, 300);

export const debounceUpdatePremiumCollectionRequest = AwesomeDebouncePromise(updatePremiumCollectionRequest, 1000);
