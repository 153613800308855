import React from 'react';
import { Form } from 'react-bootstrap';

const InsuranceTypeRadio = ({ selectedInsuranceType, setSelectedInsuranceType }) => {
  const insuranceTypeOptions = [
    { label: 'Generales', value: 'general' },
    { label: 'Vida', value: 'life' }
  ];

  return (
    <Form>
      <div className="mb-3">
        {insuranceTypeOptions.map(option => (
          <Form.Check
            key={option.value}
            inline
            label={option.label}
            name="insuranceType"
            type="radio"
            id={`insurance-type-${option.value}`}
            value={option.value}
            checked={selectedInsuranceType === option.value}
            onChange={e => setSelectedInsuranceType(e.target.value)}
          />
        ))}
      </div>
    </Form>
  );
};

export default InsuranceTypeRadio;
