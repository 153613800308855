/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import {
  BasicActionBtns,
  BtnTooltip,
  ButtonTooltip,
  DataTableDatePicker,
  DataTableInput,
  DatatableMainInfo,
  DataTableNumberFormat,
  DataTableSelect,
  ReadMore
} from '..';
import { disabledEditableCellByNicoCode } from '../../services/utils';
import { statusOptions } from './attributeOptions';
import basicInfoRoles from './basicInfoRoles';
import UploadFiles from './UploadFiles';
import SendEmailBtn from './SendEmailBtn';

const getEditableStatus = (isAdmin, can, nicoCode) => {
  const showEditable = isAdmin || can.edit;
  const isDisabled = !can.edit || (!isAdmin && disabledEditableCellByNicoCode(nicoCode));
  return { showEditable, isDisabled };
};

const columns = memoize(({ handleButtonClick, handleUpdateRequest, userModule }) => {
  const currentUserOptions = basicInfoRoles[userModule];
  const { basePath, can, isAdmin } = currentUserOptions;
  const totalPremiumReportedTooltip = `Si el valor ingresado en esta columna difiere por un 5% o más (positivo o negativo)<br/> 
  al ingresado en la columna 'Prima calculada total bruta', verás el fondo en amarillo.`;

  const installmentColumnTooltip = `Cadencia Única: se muestra el número de cuota adeudada, <br/>
    respecto al número total de cuotas suscritas en el plan de pago.<br/>
    Cadencia Mensual: se muestra el mes de cobertura por el cual <br/>
    se genera la cobranza.`;

  const premiumCollectionColumns = [
    {
      name: 'Cadencia',
      selector: 'cadence_type',
      sortable: true,
      grow: '1',
      cell: ({ insurancePolicyCadenceType, translatedInsurancePolicyCadenceType }) => (
        <BtnTooltip
          icon={insurancePolicyCadenceType.includes('unique') ? 'play-stop' : 'menu-grid-r'}
          iconSize="xl"
          variant="transparent"
          className="black"
          tooltipText={translatedInsurancePolicyCadenceType}
        />
      )
    },
    {
      name: 'Cuenta',
      selector: 'account',
      sortable: true,
      grow: '1',
      minWidth: '250px',
      cell: ({ accountId, accountName, accountNationalIdentification }) => (
        <DatatableMainInfo
          noIcon
          linkTitle={can.redirectToAccount && `${basePath}/accounts/${accountId}`}
          title={accountName}
          subtitle={accountNationalIdentification}
        />
      )
    },
    {
      name: 'N° póliza',
      selector: 'policy_number',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({
        insurancePolicyId,
        insurancePolicyPolicyNumber,
        insurancePolicyValidityEnd,
        insurancePolicyValidityStart
      }) => (
        <DatatableMainInfo
          noIcon
          linkTitle={can.redirectToInsurancePolicy && `${basePath}/insurance_policies/${insurancePolicyId}`}
          title={insurancePolicyPolicyNumber}
          subtitle={`I: ${insurancePolicyValidityStart}`}
          secondarySubtitle={`V: ${insurancePolicyValidityEnd}`}
        />
      )
    },
    {
      name: 'Compañía',
      selector: 'insurance_company',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ insuranceCompanyLabel }) => insuranceCompanyLabel
    },
    {
      name: 'Código NICO',
      selector: 'nico_code',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ translatedInsurancePolicyNicoCode }) => translatedInsurancePolicyNicoCode
    },
    {
      name: 'Fecha cobranza',
      selector: 'collection_date',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ collectionDate }) => collectionDate
    },
    {
      name: 'Estado',
      selector: 'status',
      sortable: true,
      grow: '1',
      minWidth: '260px',
      cell: ({ id, status, translatedStatus, insurancePolicyNicoCode }) => {
        const { showEditable, isDisabled } = getEditableStatus(isAdmin, can, insurancePolicyNicoCode);
        return showEditable ? (
          <DataTableSelect
            id={id}
            attribute="status"
            defaultValue={status}
            options={statusOptions}
            updateRequest={handleUpdateRequest}
            updateDataTable
            disabled={isDisabled}
          />
        ) : (
          translatedStatus
        );
      }
    },
    {
      name: (
        <>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span className="mr-1">Cuota</span>
            <ButtonTooltip tooltipText={installmentColumnTooltip} direction="right">
              ?
            </ButtonTooltip>
          </span>
        </>
      ),
      selector: 'installment_number',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ installmentColumn }) => installmentColumn
    }
  ];

  if (isAdmin) {
    premiumCollectionColumns.push({
      name: 'Prima calculada total bruta',
      selector: 'total_premium_calculated',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ parsedTotalPremiumCalculated }) => parsedTotalPremiumCalculated
    });
  }

  premiumCollectionColumns.push({
    name: 'Prima informada afecta neta',
    selector: 'net_affect_premium_reported',
    sortable: true,
    grow: '1',
    minWidth: '170px',
    cell: ({
      id,
      currencyPrefix,
      parsedNetAffectPremiumReported,
      netAffectPremiumReported,
      insurancePolicyNicoCode
    }) => {
      const { showEditable, isDisabled } = getEditableStatus(isAdmin, can, insurancePolicyNicoCode);
      return showEditable ? (
        <DataTableNumberFormat
          id={id}
          prefix={`${currencyPrefix} `}
          attribute="net_affect_premium_reported"
          defaultValue={netAffectPremiumReported}
          updateRequest={handleUpdateRequest}
          updateDataTable={false}
          updateDataTableItem
          disabled={isDisabled}
        />
      ) : (
        parsedNetAffectPremiumReported || '-'
      );
    }
  });

  premiumCollectionColumns.push({
    name: 'Prima informada exenta neta',
    selector: 'net_exempt_premium_reported',
    sortable: true,
    grow: '1',
    minWidth: '170px',
    cell: ({
      id,
      currencyPrefix,
      parsedNetExemptPremiumReported,
      netExemptPremiumReported,
      insurancePolicyNicoCode
    }) => {
      const { showEditable, isDisabled } = getEditableStatus(isAdmin, can, insurancePolicyNicoCode);
      return showEditable ? (
        <DataTableNumberFormat
          id={id}
          prefix={`${currencyPrefix} `}
          attribute="net_exempt_premium_reported"
          defaultValue={netExemptPremiumReported}
          updateRequest={handleUpdateRequest}
          updateDataTable={false}
          updateDataTableItem
          disabled={isDisabled}
        />
      ) : (
        parsedNetExemptPremiumReported || '-'
      );
    }
  });

  premiumCollectionColumns.push(
    {
      name: (
        <>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span className="mr-1">Prima informada total bruta</span>
            <ButtonTooltip tooltipText={totalPremiumReportedTooltip} direction="right">
              ?
            </ButtonTooltip>
          </span>
        </>
      ),
      selector: 'total_premium_reported',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({
        id,
        currencyPrefix,
        parsedTotalPremiumReported,
        totalPremiumReported,
        differenceExceeded,
        insurancePolicyNicoCode
      }) => {
        const { showEditable, isDisabled } = getEditableStatus(isAdmin, can, insurancePolicyNicoCode);

        return showEditable ? (
          <DataTableNumberFormat
            id={id}
            prefix={`${currencyPrefix} `}
            attribute="total_premium_reported"
            defaultValue={totalPremiumReported}
            updateRequest={handleUpdateRequest}
            differenceExceeded={differenceExceeded}
            updateDataTable={false}
            updateDataTableItem
            disabled={isDisabled}
          />
        ) : (
          parsedTotalPremiumReported || '-'
        );
      }
    },
    {
      name: 'N° factura',
      selector: 'invoice_number',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ id, invoiceNumber, insurancePolicyNicoCode }) => {
        const { showEditable, isDisabled } = getEditableStatus(isAdmin, can, insurancePolicyNicoCode);
        return showEditable ? (
          <DataTableInput
            id={id}
            attribute="invoice_number"
            defaultValue={invoiceNumber}
            updateRequest={handleUpdateRequest}
            updateDataTable={false}
            disabled={isDisabled}
          />
        ) : (
          invoiceNumber || '-'
        );
      }
    },
    {
      name: 'Fecha emisión factura',
      selector: 'company_issue_date',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ id, companyIssueDate, insurancePolicyNicoCode }) => {
        const { showEditable, isDisabled } = getEditableStatus(isAdmin, can, insurancePolicyNicoCode);
        return showEditable ? (
          <DataTableDatePicker
            id={id}
            attribute="company_issue_date"
            defaultValue={companyIssueDate}
            updateRequest={handleUpdateRequest}
            updateDataTable={false}
            disabled={isDisabled}
          />
        ) : (
          companyIssueDate || '-'
        );
      }
    },
    {
      name: 'Fecha pago de cliente',
      selector: 'account_payment_date',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ id, accountPaymentDate, insurancePolicyNicoCode }) => {
        const { showEditable, isDisabled } = getEditableStatus(isAdmin, can, insurancePolicyNicoCode);
        return showEditable ? (
          <DataTableDatePicker
            id={id}
            attribute="account_payment_date"
            defaultValue={accountPaymentDate}
            updateRequest={handleUpdateRequest}
            updateDataTable
            disabled={isDisabled}
          />
        ) : (
          accountPaymentDate || '-'
        );
      }
    }
  );

  if (isAdmin) {
    premiumCollectionColumns.push(
      {
        name: 'Ejecutivo',
        selector: 'executive_manager',
        sortable: true,
        grow: '1',
        minWidth: '170px',
        cell: ({ executiveManagerLabel }) => executiveManagerLabel
      },
      {
        name: 'Corredor',
        selector: 'insurance_broker',
        sortable: true,
        grow: '1.5',
        minWidth: '170px',
        cell: ({ insuranceBrokerCompanyName, insuranceBrokerName }) => (
          <DatatableMainInfo noIcon title={insuranceBrokerName} subtitle={insuranceBrokerCompanyName} />
        )
      }
    );
  }

  premiumCollectionColumns.push({
    name: 'Comentarios',
    selector: 'comments',
    sortable: true,
    grow: '1',
    minWidth: 'auto',
    cell: ({ id, comments, insurancePolicyNicoCode }) => {
      const { isDisabled } = getEditableStatus(isAdmin, can, insurancePolicyNicoCode);
      return !isDisabled ? (
        <ReadMore
          isEditable
          iconBtn
          title="Comentarios"
          item={{ id, attribute: 'comments', defaultValue: comments, handleUpdateRequest }}
        >
          {comments}
        </ReadMore>
      ) : (
        (comments && <ReadMore title="Comentarios">{comments}</ReadMore>) || '-'
      );
    }
  });

  if (isAdmin) {
    premiumCollectionColumns.push({
      grow: '1',
      minWidth: 'auto',
      cell: ({ insurancePolicyCategory, insurancePolicyId }) => (
        <>
          {insurancePolicyCategory === 'life' && (
            <BtnTooltip
              icon="notes"
              iconSize="sm"
              variant="transparent"
              className="black"
              tooltipText="Ir a nómina"
              to={`/admin/insurance_policies/${insurancePolicyId}/beneficiaries`}
            />
          )}
        </>
      )
    });
  }
  premiumCollectionColumns.push({
    grow: '1',
    minWidth: 'auto',
    cell: item => {
      const { isDisabled } = getEditableStatus(isAdmin, can, item.insurancePolicyNicoCode);
      return !isDisabled && <UploadFiles item={item} updateRequest={handleUpdateRequest} />;
    }
  });

  premiumCollectionColumns.push({
    grow: '1',
    minWidth: 'auto',
    cell: item => {
      const { isDisabled } = getEditableStatus(isAdmin, can, item.insurancePolicyNicoCode);
      return isAdmin ? (
        <SendEmailBtn collectionItem={item} userModule={userModule} />
      ) : (
        !isDisabled && <SendEmailBtn collectionItem={item} userModule={userModule} />
      );
    }
  });

  premiumCollectionColumns.push({
    grow: '1',
    minWidth: 'auto',
    cell: item => {
      const { isDisabled } = getEditableStatus(isAdmin, can, item.insurancePolicyNicoCode);
      return (
        !isDisabled && <BasicActionBtns withDestroy transparentStyle item={item} clickHandler={handleButtonClick} />
      );
    }
  });

  return premiumCollectionColumns;
});

export default columns;
