const basicInsuranceCompany = {
  id: '',
  active: true,
  category: '',
  collectionExecutiveEmail: '',
  collectionExecutiveFullName: '',
  collectionExecutivePhoneNumber: '',
  executiveFullName: '',
  executiveEmail: '',
  executivePhoneNumber: '',
  fullName: '',
  lifeCollectionExecutiveEmail: '',
  lifeCollectionExecutiveFullName: '',
  lifeCollectionExecutivePhoneNumber: '',
  lifeExecutiveEmail: '',
  lifeExecutiveFullName: '',
  lifeExecutivePhoneNumber: '',
  lifePaymentTransferData: '',
  lifePremiumPaymentLink: '',
  lifeSinisterExecutiveEmail: '',
  lifeSinisterExecutiveFullName: '',
  lifeSinisterExecutivePhoneNumber: '',
  lifeSinisterReportLink: '',
  lifeWebsiteLink: '',
  logo: {},
  name: '',
  nationalIdentification: '',
  paymentTransferData: '',
  premiumPaymentLink: '',
  readPolicyAiInstructions: '',
  validatePolicyAiInstructions: '',
  showInLanding: false,
  sinisterExecutiveEmail: '',
  sinisterExecutiveFullName: '',
  sinisterExecutivePhoneNumber: '',
  sinisterReportLink: '',
  websiteLink: ''
};

export default basicInsuranceCompany;
