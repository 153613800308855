import React from 'react';
import { useSelector } from 'react-redux';

import { AutoSaveCommentsForm, HistoricalRecordsShow } from '..';
import { AttachedFile, SwitchButton } from '../InsurancePolicies';
// import { InfoCardAdditionalDocument } from '../../components/Shared';
import sendDocumentEmail from '../Shared/SendDocumentEmail';
import { insuranceContractProps, insurancePolicyProps } from '../Shared/SendEmailModalProps';
// import { InfoCardEndorsement, InfoCardGeneral, InfoCardMonetary, InfoCardValidity } from './InfoCard';
import basicInfoRoles from '../../screens/AdminScreens/Contract/basicInfoRoles';
import InfoCardGeneral from './InfoCard/InfoCardGeneral';
import { InfoCardAdditionalDocument } from '../Shared';
import InfoCardItem from './InfoCard/InfoCardItem';
import InfoCardInvoiceDocument from '../Shared/InfoCard/InfoCardInvoiceDocument';

const ContractItemInfo = ({ contractItem, setMoreData, userModule, modelUrl }) => {
  // GET CURRENT ROLE DATA
  const currentUserOptions = basicInfoRoles[userModule];
  const { can, debounceUpdateContractItemRequest, isAdmin, updateRequest } = currentUserOptions;

  const { user: currentUser } = useSelector(state => state.auth);

  const {
    id,
    adminComments,
    comments,
    debtInstallment,
    debtStartDate,
    insurancePolicyHistories = [],
    insuranceContractId,
    parsedDebtAmount,
    insurancePolicyInfo = {},
    insuranceContractInfo = {}
  } = contractItem;

  const { withDebt, withSinister, contractInfo = {} } = insurancePolicyInfo;
  const hasPolicyContract = Object.keys(contractInfo).length > 0;
  const policyUrl = userModule === 'admin' ? '/admin/insurance_policies' : '/insurance_policies';

  let debtCardText = withDebt ? `Fecha de inicio: ${debtStartDate}` : '';
  if (parsedDebtAmount) debtCardText += `\nMonto deuda: ${parsedDebtAmount}`;
  if (debtInstallment) debtCardText += `\nNúmero cuota: ${debtInstallment}`;

  const { SendEmailModal: SendContractEmailModal, openEmailModal: openContractEmailModal } = sendDocumentEmail({
    fromAdmin: isAdmin,
    showCcField: true,
    showBccField: true,
    textCancelButton: 'Cerrar',
    fileSection: true,
    fileSectionLabel: 'Adjuntos'
  });

  const { SendEmailModal: SendPolicyEmailModal, openEmailModal: openPolicyEmailModal } = sendDocumentEmail({
    fromAdmin: isAdmin,
    showCcField: true,
    showBccField: true,
    textCancelButton: 'Cerrar',
    fileSection: true,
    fileSectionLabel: 'Adjuntos'
  });

  return (
    <>
      <div className="insurance-policy-show-layout main">
        <div className="general-information">
          <InfoCardGeneral userModule={userModule} contractItem={contractItem} />

          <InfoCardAdditionalDocument
            modelName="insuranceContract"
            modelUrl={modelUrl}
            object={contractItem}
            additionalDocuments={insuranceContractInfo.additionalDocumentsInfo}
            setMoreData={setMoreData}
            resourceId={insuranceContractId.toString()}
            updateRequest={updateRequest}
            canAddDocuments={can.addAdditionalDocument}
          />
        </div>
        <div className="details-information">
          <InfoCardItem contractItem={contractItem} userModule={userModule} />
        </div>
        <div className="side-components">
          {insurancePolicyInfo && (
            <>
              <SwitchButton
                id={id}
                title="Siniestro"
                attribute="with_sinister"
                icon={{ name: 'smile-sad', variant: 'danger' }}
                cardVariant={withSinister ? 'danger' : ''}
                initialValue={withSinister}
                readonly
                setMoreData={setMoreData}
              />
              <SwitchButton
                id={id}
                title="Deuda"
                attribute="with_debt"
                subtitle={debtCardText}
                icon={{ name: 'dollar', variant: 'danger' }}
                cardVariant={withDebt ? 'danger' : ''}
                initialValue={withDebt}
                readonly
                setMoreData={setMoreData}
              />
              <AttachedFile
                title="Contrato"
                attribute="contract"
                fileUrl={insuranceContractInfo.contractInfo?.fileUrl}
                extraButton={
                  can.sendDocument
                    ? {
                        name: 'Enviar',
                        active: true,
                        disabled: !insuranceContractInfo.contractInfo?.fileUrl,
                        onClick: openContractEmailModal
                      }
                    : false
                }
              />

              <AttachedFile
                title="Póliza"
                attribute="policy"
                fileUrl={insurancePolicyInfo.contractInfo?.fileUrl}
                extraButton={
                  can.sendDocument
                    ? {
                        name: 'Enviar',
                        active: true,
                        disabled: !insurancePolicyInfo.contractInfo?.fileUrl,
                        onClick: openPolicyEmailModal
                      }
                    : false
                }
              />

              <InfoCardInvoiceDocument
                modelName="insuranceContract"
                modelUrl={modelUrl}
                object={contractItem}
                additionalDocuments={insuranceContractInfo.invoicesInfo || []}
                setMoreData={setMoreData}
                resourceId={insuranceContractId.toString()}
                updateRequest={updateRequest}
                canAddDocuments={can.addInvoiceDocument}
              />
              <SendContractEmailModal {...insuranceContractProps({ contractItem, currentUser })} />
              {hasPolicyContract && (
                <SendPolicyEmailModal {...insurancePolicyProps({ insurancePolicy: insurancePolicyInfo })} />
              )}
            </>
          )}
        </div>
      </div>

      <div className={`insurance-policy-show-layout ${can.autoSaveComment ? 'secondary' : ''}`}>
        {can.autoSaveComment && (
          <AutoSaveCommentsForm
            row={7}
            withTitle
            resourceId={id.toString()}
            resourceComments={comments}
            resourcePath="contract_item[comments]"
            debouncedUpdateRequest={debounceUpdateContractItemRequest}
          />
        )}

        <HistoricalRecordsShow
          withTitle
          historicalRecords={insurancePolicyHistories}
          baseUrl={policyUrl}
          linkField="insurancePolicyId"
        />
      </div>

      {can.autoSaveComment && isAdmin && (
        <div className="mt-5">
          <AutoSaveCommentsForm
            withTitle
            title="Nota admin"
            resourceId={id.toString()}
            resourceComments={adminComments}
            resourcePath="contract_item[admin_comments]"
            debouncedUpdateRequest={debounceUpdateContractItemRequest}
          />
        </div>
      )}
    </>
  );
};

export default ContractItemInfo;
