import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
// import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { SimpleCenteredModal, StatisticCard } from '../../../components';
import { ModalBodyIcon } from '../../../components/Shared';
import { bulkUploadContractItemsRequest } from '../../../requests/admin/bulkUploads';
import ContractItemForm from './Form/ContractItemForm';

const ContractItem = ({ handleModalClose, handleModalOpen, ...props }) => {
  const { setModalBody, activeModal, setErrorMessage, errorMessage } = props;

  const [modalConfirmationBody, setModalConfirmationBody] = useState('');
  const [modalConfirmationShow, setModalConfirmationShow] = useState(false);
  const dispatch = useDispatch();

  const handleSuccessRequest = response => {
    setErrorMessage(response.data);
    if (response.data.errors.length > 0) return;

    dispatch(sendAlert({ kind: 'success', message: 'Bienes agregados con éxito' }));
    handleModalClose();
  };

  const handleContractItemsRequest = (selectedFile, setSubmitting, insuranceBrokerId) => {
    setModalConfirmationShow(false);
    const sendParams = { contract_items_file: selectedFile, insurance_broker_id: insuranceBrokerId };

    bulkUploadContractItemsRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  const handleModalConfirmation = (selectedFile, setSubmitting, insuranceBrokerId) => {
    setModalConfirmationShow(true);
    setSubmitting(true);
    setModalConfirmationBody(
      <ModalBodyIcon
        icon="reorder"
        iconVariant="danger"
        iconClass="text-dark"
        submitVariant="strong-danger"
        content="¿Estás seguro de importar estos bienes?"
        highlightedText=""
        highlightedVariant="white"
        subContent=""
        confirmText={false}
        formRequest={() => handleContractItemsRequest(selectedFile, setSubmitting, insuranceBrokerId)}
        handleModalClose={() => {
          setModalConfirmationShow(false);
          setSubmitting(false);
        }}
      />
    );
  };

  const handleModalBody = () => (
    <ContractItemForm
      formRequest={handleModalConfirmation}
      handleModalClose={handleModalClose}
      errorMessage={errorMessage}
    />
  );

  const handleOnClickBtn = () =>
    handleModalOpen({ title: 'Bienes', body: handleModalBody, size: 'xl', activeModalName: 'contractItem' });

  const handleUpdateModalBody = () => setModalBody(handleModalBody);

  useEffect(() => {
    if (activeModal === 'contractItem') {
      handleUpdateModalBody();
    }
  }, [activeModal, errorMessage]);

  return (
    <Col sm={6} lg={4} className="mt-5">
      <StatisticCard icon="export" variant="primary-light" title="Bienes" clickable onClick={handleOnClickBtn} />

      <SimpleCenteredModal
        show={modalConfirmationShow}
        body={modalConfirmationBody}
        onHide={() => null}
        closeButton={false}
      />
    </Col>
  );
};

export default ContractItem;
