import React, { useState, useEffect } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { BtnTooltip, IconBtn } from '../../Utils/Button';
import IconGG from '../../Utils/Icon';

import { FormikInput } from '../../Utils/Input';
import { SimpleCenteredModal } from '../../Utils/Modal';
import AccountModalForm from './AccountModalForm';
import AccountRemoteSelector from './AccountRemoteSelector';
import { InsuranceBrokerRemoteSelector } from '../../Shared';
import ExecutiveManagerRemoteSelector from '../../Shared/ExecutiveManagerRemoteSelector';

const AccountSection = ({
  allowUpdate,
  setAllowUpdate,
  fromAdmin,
  modelName,
  isProposal,
  fromLeadProposal,
  userModule,
  showAdditionalContacts,
  showAdditionalCollectionContacts,
  setShowAdditionalContacts,
  setShowAdditionalCollectionContacts,
  action
}) => {
  const { errors, touched, values, setFieldValue } = useFormikContext();
  const [modalShow, setModalShow] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  // const adminUrl = fromAdmin ? '/admin' : '';
  const {
    insuranceBrokerId,
    executiveManagerId,
    responsibleEmail2,
    responsibleEmail3,
    collectionEmail2,
    collectionEmail3
  } = getIn(values, modelName);

  const handleModalClose = () => setModalShow(false);

  const tooltipTextResponsibleEmail = () => {
    return `Ingresa el correo electrónico de la persona a cargo de la gestión comercial de esta póliza.<br/>
    Esta puede ser distinta a los contactos de la Cuenta, el Contratante y el Asegurado.<br/>
    Esta persona tendrá acceso a la póliza a través del portal NICO y será el destinatario predeterminado<br/>
    para el envío de cotizaciones, propuestas, pólizas, endosos, etc.`;
  };

  const tooltipTextCollectionEmail = () => {
    return `Ingresa el correo electrónico de la persona a cargo del pago de la prima de esta póliza.<br/>
    Esta puede ser distinta a los contactos de la Cuenta, el Contratante, el Asegurado y el Contacto comercial.<br/>
    Esta persona será el destinatario predeterminado para el envío de cobranza de primas.`;
  };

  const tooltipTextAccount = () => {
    return `Persona natural o jurídica a quién está asociada la Póliza.<br/>
    No necesariamente es igual al Contratante o Asegurado`;
  };

  const showInsuranceBrokerSelector = () => {
    return fromAdmin || userModule === 'insured';
  };

  const handleShowAdditionalContacts = () => {
    setShowAdditionalContacts(!showAdditionalContacts);
    setFieldValue(`${modelName}[responsibleEmail2]`, '');
    setFieldValue(`${modelName}[responsibleEmail3]`, '');
  };

  const handleShowAdditionalCollectionContacts = () => {
    setShowAdditionalCollectionContacts(!showAdditionalCollectionContacts);
    setFieldValue(`${modelName}[collectionEmail2]`, '');
    setFieldValue(`${modelName}[collectionEmail3]`, '');
  };

  const showResponsibleEmailsEdit = () => {
    return action === 'edit' && (responsibleEmail2 || responsibleEmail3);
  };

  const showCollectionEmailsEdit = () => {
    return action === 'edit' && (collectionEmail2 || collectionEmail3);
  };

  useEffect(() => {
    if (!showAdditionalContacts && showResponsibleEmailsEdit()) {
      setShowAdditionalContacts(true);
    }
  }, [responsibleEmail2, responsibleEmail3]);

  useEffect(() => {
    if (!showAdditionalCollectionContacts && showCollectionEmailsEdit()) {
      setShowAdditionalCollectionContacts(true);
    }
  }, [collectionEmail2, collectionEmail3]);

  return (
    <>
      <section className="form-section mb-5 w-100 account-section">
        <p className="section-title">Información de la Cuenta</p>

        <Row className="account-section__form">
          {showInsuranceBrokerSelector() && (
            <Col md={4} lg={4}>
              <Field name={`${modelName}[insuranceBrokerId]`}>
                {({ field }) => (
                  <InsuranceBrokerRemoteSelector
                    fromAdmin={fromAdmin}
                    field={field}
                    modelName={modelName}
                    tooltipText="Usuario Corredor a quien pertenece la Cuenta (Corredor, Partner o Directo)."
                  />
                )}
              </Field>
            </Col>
          )}
          <Col md={4} lg={4}>
            <Field name={`${modelName}[accountId]`}>
              {({ field }) => (
                <AccountRemoteSelector
                  key={refreshKey} // Forzar actualización
                  field={field}
                  modelName={modelName}
                  fromAdmin={fromAdmin}
                  disabled={fromAdmin && !insuranceBrokerId}
                  userModule={userModule}
                  tooltipText={tooltipTextAccount()}
                />
              )}
            </Field>
          </Col>
          {fromAdmin && (
            <Col md={4} lg={4}>
              <Field name={`${modelName}[executiveManagerId]`}>
                {({ field }) => (
                  <ExecutiveManagerRemoteSelector
                    allowUpdate={allowUpdate}
                    setAllowUpdate={setAllowUpdate}
                    field={field}
                    modelName={modelName}
                    fromAdmin={fromAdmin}
                    // disabled={fromAdmin && !accountId}
                    tooltipText="Ejecutivo NICO que atenderá esta Póliza"
                  />
                )}
              </Field>
            </Col>
          )}
          <Col md={4} lg={4}>
            <Row>
              <Col md={10} className="pr-1">
                <Field name={`${modelName}[responsibleEmail]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      label="Contacto comercial"
                      placeholder="Correo electrónico"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      tooltipText={tooltipTextResponsibleEmail()}
                    />
                  )}
                </Field>
              </Col>
              <Col
                md={2}
                className="d-flex align-items-center justify-content-center p-0"
                style={{ marginLeft: '-6px' }}
              >
                <BtnTooltip
                  onClick={handleShowAdditionalContacts}
                  icon={showAdditionalContacts ? 'remove' : 'add'}
                  tooltipText={
                    showAdditionalContacts
                      ? 'Eliminar contactos comerciales adicionales'
                      : 'Agregar contactos comerciales adicionales'
                  }
                />
              </Col>
            </Row>
          </Col>

          {showAdditionalContacts && (
            <>
              <Col md={4} lg={4}>
                <Field name={`${modelName}[responsibleEmail2]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      label="Contacto comercial 2"
                      placeholder="Correo electrónico"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      tooltipText={tooltipTextResponsibleEmail()}
                    />
                  )}
                </Field>
              </Col>
              <Col md={4} lg={4}>
                <Field name={`${modelName}[responsibleEmail3]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      label="Contacto comercial 3"
                      placeholder="Correo electrónico"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      tooltipText={tooltipTextResponsibleEmail()}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}

          <Col md={4} lg={4}>
            <Row>
              <Col md={10} className="pr-1">
                <Field name={`${modelName}[collectionEmail]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      label="Contacto cobranza"
                      placeholder="Correo electrónico"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      tooltipText={tooltipTextCollectionEmail()}
                    />
                  )}
                </Field>
              </Col>
              <Col
                md={2}
                className="d-flex align-items-center justify-content-center p-0"
                style={{ marginLeft: '-6px' }}
              >
                <BtnTooltip
                  onClick={handleShowAdditionalCollectionContacts}
                  icon={showAdditionalCollectionContacts ? 'remove' : 'add'}
                  tooltipText={
                    showAdditionalCollectionContacts
                      ? 'Eliminar contactos de cobranza adicionales'
                      : 'Agregar contactos de cobranza adicionales'
                  }
                />
              </Col>
            </Row>
          </Col>
          {showAdditionalCollectionContacts && (
            <>
              <Col md={4} lg={4}>
                <Field name={`${modelName}[collectionEmail2]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      label="Contacto cobranza 2"
                      placeholder="Correo electrónico"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      tooltipText={tooltipTextCollectionEmail()}
                    />
                  )}
                </Field>
              </Col>
              <Col md={4} lg={4}>
                <Field name={`${modelName}[collectionEmail3]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      label="Contacto cobranza 3"
                      placeholder="Correo electrónico"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      tooltipText={tooltipTextCollectionEmail()}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}

          <Col md={4} lg={4}>
            <Field name={`${modelName}[externalBroker]`}>
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Corredor externo"
                  placeholder="Nombre corredor externo"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>

          {!isProposal && !fromLeadProposal && (
            <>
              <Col sm={12} md={5} lg={5} className="d-flex align-items-center">
                <Alert variant="warning" className="account-section__alert mb-0">
                  <IconGG className="mr-2" icon="danger" size="sm" />
                  <p className="detail font-weight-bold">
                    Si no encuentras la Cuenta, puedes crear una nueva a continuación.
                  </p>
                </Alert>
              </Col>
              <Col sm={12} md={3} lg={3} className="d-flex align-items-center">
                <IconBtn
                  icon="add"
                  className="mt-lg-0"
                  onClick={() => setModalShow(true)} // disabled={onRequest}
                  disabled={fromAdmin && !insuranceBrokerId}
                >
                  Nueva cuenta
                </IconBtn>
              </Col>
            </>
          )}
        </Row>

        <SimpleCenteredModal
          size="xl"
          title="Crear nueva cuenta"
          show={modalShow}
          onHide={handleModalClose}
          body={
            <AccountModalForm
              insuranceBrokerId={insuranceBrokerId}
              fromAdmin={fromAdmin}
              handleCancelButton={handleModalClose}
              setRefreshKey={setRefreshKey}
              executiveManagerId={executiveManagerId}
            />
          }
        />
      </section>

      <hr className="w-100 mb-5" />
    </>
  );
};

export default AccountSection;
