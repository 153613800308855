import React from 'react';

import useFetchForRemoteSelect from '../../../hooks/useFetchForRemoteSelect';
import {
  debounceIndexInsuranceCompanyRequest,
  indexInsuranceCompanyRequest
} from '../../../requests/insuranceCompanies';
import { InputRemoteSelect } from '../../Utils/Select';

const InsuranceCompanySelect = ({ disabled, ...props }) => {
  const { selectedInsuranceType, selectedInsuranceCompany, setSelectedInsuranceCompany } = props;

  const { options: companies, selectedOption: selectedCompany, fetchOptions: fetchCompanies } = useFetchForRemoteSelect(
    {
      indexRequest: indexInsuranceCompanyRequest,
      debouncedIndexRequest: debounceIndexInsuranceCompanyRequest,
      value: selectedInsuranceCompany?.value,
      initialParams: {
        category: selectedInsuranceType || '',
        for_selector: true
      },
      nestedDependency: selectedInsuranceType || ''
    }
  );

  const handleCompanyChange = data => {
    setSelectedInsuranceCompany(data);
  };

  return (
    <InputRemoteSelect
      abbr
      isClearable
      disabled={disabled}
      label="Aseguradora"
      defaultOptions={companies}
      value={selectedCompany}
      request={fetchCompanies}
      onChange={data => handleCompanyChange(data)}
    />
  );
};

export default InsuranceCompanySelect;
