/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import linkedInLogo from '../../../assets/images/Landing/linkedin_logo.png';
import linkedInLogoDark from '../../../assets/images/Landing/linkedin_logo_dark.png';
import { IconBtn } from '../../Utils/Button';
import IconGG from '../../Utils/Icon';
import MainLogo from '../../Utils/MainLogo';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const LandingFooter = ({ settings, extraSettings = {} }) => {
  const { textOnScroll = 'white' } = extraSettings;
  const history = useHistory();
  const { type, translateType } = useGetRoleByRoute();
  const typeClass = type === 'partner' || textOnScroll === 'dark' ? 'landing--footer-text-dark' : '';

  const navigateToContact = () => history.push(`/${type === 'company' ? 'asegurado' : translateType}/landing_contact`);

  const footerLinks = {
    broker: {
      quickLinks: [
        { name: 'Inicio', url: '/corredor' },
        { name: 'Planes', url: '/corredor/planes' },
        { name: 'Blog', url: 'https://blog.nicoseguros.com/' },
        { name: 'Términos y condiciones', url: '/terminos' }
      ],
      otherSolutions: [
        { name: 'Partner', url: '/partner' },
        { name: 'Asegurado', url: '/asegurado' }
      ],
      buttonText: 'Empieza GRATIS'
    },
    partner: {
      quickLinks: [
        { name: 'Inicio', url: '/partner' },
        { name: 'Cómo funciona', url: '/partner/como' },
        { name: 'Blog', url: 'https://blog.nicoseguros.com/' },
        { name: 'Términos y condiciones', url: '/terminos' }
      ],
      otherSolutions: [
        { name: 'Corredor', url: '/corredor' },
        { name: 'Asegurado', url: '/asegurado' }
      ],
      buttonText: 'Quiero saber más'
    },
    insured: {
      quickLinks: [
        { name: 'Inicio', url: '/asegurado' },
        { name: 'Empresas', url: '/asegurado/companias' },
        { name: 'Personas', url: '/asegurado/personas' },
        { name: 'Blog', url: 'https://blog.nicoseguros.com/' },
        { name: 'Términos y condiciones', url: '/terminos' }
      ],
      otherSolutions: [
        { name: 'Corredor', url: '/corredor' },
        { name: 'Partner', url: '/partner' }
      ],
      buttonText: 'Quiero asesoría GRATIS'
    }
  };

  footerLinks.company = footerLinks.insured;

  const footerConfig = footerLinks[type];

  const navigateTo = path => {
    if (path.match(/^https?:\/\//)) {
      window.open(path, '_blank', 'noopener,noreferrer');
    } else {
      history.push(path);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  const { contactPhone, linkedinUrl = 'https://www.linkedin.com/company/nico-seguros/', footerContactEmail } = settings;

  return (
    <footer
      className={`landing--footer-container landing--footer-${type}`}
      style={{
        '--inline-primary-color': extraSettings.landingPrimaryColor,
        '--inline-secondary-color': extraSettings.landingSecondaryColor
      }}
    >
      <div className="landing--footer">
        <div className="landing--footer__logo">
          <Link to="/">
            <MainLogo type={type === 'partner' ? 'dark' : 'light'} />
          </Link>
        </div>

        {/* Links rápidos */}
        <div className="landing--footer__buttons">
          <p className={`section-title text-color-white ${typeClass}`}>Links rápidos</p>
          <div className="d-flex flex-column">
            {footerConfig.quickLinks.map((link, index) => (
              <p
                key={`link-${index.toString()}`}
                onClick={() => navigateTo(link.url)}
                className={`footer-link ${typeClass}`}
              >
                {link.name}
              </p>
            ))}
          </div>

          <IconBtn
            onClick={() => navigateToContact()}
            variant="secondary"
            className={`rouded-border ${type === 'partner' && 'dark-background'} footer-button-${type}`}
            style={{
              '--inline-primary-color': extraSettings.landingPrimaryColor,
              '--inline-secondary-color': extraSettings.landingSecondaryColor
            }}
          >
            {footerConfig.buttonText}
          </IconBtn>
        </div>

        {/* Otras soluciones */}
        <div className="landing--footer__buttons">
          <p className={`section-title text-color-white ${typeClass}`}>Otras soluciones</p>
          <div className="d-flex flex-column">
            {footerConfig.otherSolutions.map((link, index) => (
              <p
                key={`link-${index.toString()}`}
                onClick={() => navigateTo(link.url)}
                className={`footer-link ${typeClass}`}
              >
                {link.name}
              </p>
            ))}
          </div>
        </div>

        <div
          className={`landing--footer__contact-container ${type}`}
          style={{
            '--inline-primary-color': extraSettings.landingPrimaryColor,
            '--inline-secondary-color': extraSettings.landingSecondaryColor
          }}
        >
          <p className="section-title">Chile</p>
          <div className={`landing--footer__contact `}>
            <IconGG
              icon="smartphone"
              className={`${
                type === 'company'
                  ? `text-color-${textOnScroll}`
                  : (type === 'partner' && 'text-color-primary-dark') || 'text-color-secondary-light-blue'
              }`}
              size="sm"
            />
            <p className={`section-title text-color-white font-weight-500 m-0 ${typeClass}`}>
              Teléfono: {contactPhone}
            </p>
          </div>
          <div className="landing--footer__contact">
            <IconGG
              icon="comment"
              className={`${
                type === 'company'
                  ? `text-color-${textOnScroll}`
                  : (type === 'partner' && 'text-color-primary-dark') || 'text-color-secondary-light-blue'
              }`}
              size="sm"
            />
            <p className={`section-title text-color-white font-weight-500 m-0 ${typeClass}`}>
              Email: {footerContactEmail}
            </p>
          </div>
        </div>

        <div className="landing--footer__socials-container">
          <p className={`section-title text-color-white mb-4 ${typeClass}`}>Encuéntranos en</p>
          <div
            className={`landing--footer__socials ${type}`}
            style={{
              '--inline-primary-color': extraSettings.landingPrimaryColor,
              '--inline-secondary-color': extraSettings.landingSecondaryColor
            }}
          >
            <a className="d-block" href={linkedinUrl} target="_blank" rel="noreferrer">
              <Image
                src={type === 'partner' ? linkedInLogoDark : linkedInLogo}
                style={{ filter: textOnScroll === 'dark' ? 'brightness(0.5)' : '' }}
                width="34px"
                height="34px"
                alt="landing-footer"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
