import React from 'react';
import PropTypes from 'prop-types';
import { IconBtn } from '../Button';
import { ButtonTooltip } from '../Tooltips';

const StatisticCard = ({
  icon,
  variant: iconVariant,
  cardVariant,
  title,
  subtitle,
  value,
  footerButton,
  tooltipText,
  clickable,
  onClick,
  children
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={clickable ? () => onClick() : null}
      onKeyPress={clickable ? () => onClick() : null}
      className={`statistic-card ${cardVariant} position-relative ${clickable ? 'clickable' : ''}`}
    >
      <div className="statistic-card--icon">
        <IconBtn icon={icon} iconSize="lg" variant={iconVariant} noBtn className="no-shadow" />
      </div>
      <div className="statistic-card--content">
        {tooltipText && (
          <ButtonTooltip tooltipText={tooltipText} containerClassName="statistic-tooltip--container">
            ?
          </ButtonTooltip>
        )}
        <p className="section-title m-0 capitalize-text">{title}</p>
        <h6>{value}</h6>
        {subtitle && <p className="detail mt-3">{subtitle}</p>}
        {footerButton && <div className="d-flex flex-row-reverse w-100 mt-4">{footerButton}</div>}
        {children && <div className="w-100 mt-4">{children}</div>}
      </div>
    </div>
  );
};

StatisticCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  cardVariant: PropTypes.string,
  clickable: PropTypes.bool,
  onClick: PropTypes.func
};

StatisticCard.defaultProps = {
  variant: '',
  value: '',
  cardVariant: '',
  tooltipText: '',
  clickable: false,
  onClick: () => null
};

export default StatisticCard;
