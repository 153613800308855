import ApiService from '../../services/apiService';

export const contractDebtRequest = ({ dispatch, params = {}, successCallback, failureCallback, callback, source }) =>
  ApiService.request('post', '/admin/bulk_uploads/debt_contract', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const bulkUploadBeneficiariesRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/bulk_uploads/beneficiaries', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const bulkUploadContractItemsRequest = ({
  dispatch,
  params = {},
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/bulk_uploads/contract_items', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });
