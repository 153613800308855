import React, { useState } from 'react';
import { Button, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
// import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';
import { useFormikContext } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { checkAdminThreadAi } from '../../../requests/admin/lead';
import { ModalBodyIcon } from '../../Shared';
import { sendAlert } from '../../../actions/utils';
import { generateReadPolicyPrompt } from './prompts';
import { UploadFile } from '../../Utils/Upload';
import { readAdminInsurancePolicyAiRequest } from '../../../requests/admin/adminInsurancePolicies';
import { camelCaseEmptyStringRecursive, capitalizeString } from '../../../services/utils';
// import { debounceIndexAdminAccountRequest } from '../../../requests/admin/adminAccounts';
import { BasicTextArea } from '../../Utils/Input';
// import testResponse from './testResponse.json';
import InsuranceTypeRadio from './InsuranceTypeRadio';
import InsuranceCategorySelect from './InsuranceCategorySelect';
import InsuranceCompanySelect from './InsuranceCompanySelect';
import testResponse from './testResponse';
import IconGG from '../../Utils/Icon';
import { debounceIndexPaymentPlanRequest } from '../../../requests/paymentPlan';
import { readInsurancePolicyAiRequest } from '../../../requests/insurancePolicies';
import { checkThreadAi } from '../../../requests/lead';

// TODO: Refactorizar esto
const ReadPolicyModalAi = ({ handleModalClose, setInsurancePolicy, modelName, setUseAi, userModule }) => {
  const variant = 'submit';
  const iconVariant = 'success';
  const icon = 'check-o';
  const dispatch = useDispatch();
  // const [resultAi, setResultAi] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [additionalInstruction, setAdditionalInstruction] = useState('');
  const [selectedInsuranceType, setSelectedInsuranceType] = useState('');
  const [selectedInsuranceCategory, setSelectedInsuranceCategory] = useState('');
  const [selectedInsuranceCompany, setSelectedInsuranceCompany] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const RETRY_DELAY = 10000;
  const enableButtonTest = false;
  const { values, setFieldValue } = useFormikContext();

  const modalTitle = 'Leer póliza ✨';

  const submitButtonText = () => {
    return loading ? (
      <>
        Analizando...
        <span role="img" aria-label="sparkles">
          ✨
        </span>
        <Spinner animation="border" variant="primary" className="ml-2" />
      </>
    ) : (
      'Leer ✨'
    );
  };

  const getCoverageType = coverageType => {
    const requestMap = {
      afecta: 'affect',
      exenta: 'exempt'
    };

    return requestMap[coverageType] || '';
  };

  const getRenewalType = renewalType => {
    const requestMap = {
      renovable: 'renewable',
      'no renovable': 'non_renewable',
      prorrogable: 'extendable'
    };

    return requestMap[renewalType?.toLowerCase()] || 'renewable';
  };

  const getReadPolicyAIRequest = () => {
    const requestMap = {
      admin: readAdminInsurancePolicyAiRequest,
      broker: readInsurancePolicyAiRequest
    };

    return requestMap[userModule];
  };

  const getCheckThreadAiRequest = () => {
    const requestMap = {
      admin: checkAdminThreadAi,
      broker: checkThreadAi
    };

    return requestMap[userModule];
  };

  const validDate = date => {
    const formatoNumerico = /^\d{2}\/\d{2}\/\d{4}$/;

    if (!formatoNumerico.test(date)) {
      return null;
    }

    return date;
  };

  const finishModalAI = () => {
    setLoading(false);
    handleModalClose();
  };

  // const setAccountInformation = ({ query }) => {
  //   const sendParams = { for_selector: true, query };
  //   debounceIndexAdminAccountRequest({
  //     dispatch,
  //     params: sendParams,
  //     successCallback: response => {
  //       const selectedAccount = response.data.data[0];
  //       setFieldValue(`${modelName}[insuranceBrokerId]`, selectedAccount?.insurance_broker_id || '');

  //       setTimeout(() => {
  //         setFieldValue(`${modelName}[accountId]`, selectedAccount?.value || '');
  //         setFieldValue(`${modelName}[accountName]`, selectedAccount?.label || '');
  //         setFieldValue(`${modelName}[executiveManagerId]`, selectedAccount?.executive_manager_id || '');
  //         setFieldValue(`${modelName}[responsibleEmail]`, selectedAccount?.primary_contact_email);
  //         setFieldValue(`${modelName}[collectionEmail]`, selectedAccount?.primary_contact_email);
  //         finishModalAI();
  //       }, 1500);
  //     }
  //   });
  // };

  const setPaymentPlan = ({ query }) => {
    const sendParams = { for_selector: true, query };
    debounceIndexPaymentPlanRequest({
      dispatch,
      params: sendParams,
      successCallback: response => {
        const selectedPaymentPlan = response.data.data[0];
        setFieldValue(`${modelName}[paymentPlanId]`, selectedPaymentPlan?.value || '');
        setFieldValue(`${modelName}[paymentPlanName]`, selectedPaymentPlan?.label || '');
      }
    });

    finishModalAI();
  };

  // const setInsuranceCategory = () => {
  //   setFieldValue(`${modelName}[insuranceCategoryName]`, selectedInsuranceCategory?.label || '');
  //   setFieldValue(`${modelName}[insuranceCategoryId]`, selectedInsuranceCategory?.value || '');
  //   setFieldValue(
  //     `${modelName}[dictionaryDetails]`,
  //     camelCaseRecursive(selectedInsuranceCategory?.dictionary_details) || []
  //   );
  //   setFieldValue(`${modelName}[dictionaryType]`, selectedInsuranceCategory?.dictionary_type || null);
  // };

  // const setInsuranceCompany = () => {
  //   setFieldValue(`${modelName}[insuranceCompanyName]`, selectedInsuranceCompany?.label);
  //   setFieldValue(`${modelName}[insuranceCompanyFullName]`, selectedInsuranceCompany?.full_name);
  //   setFieldValue(`${modelName}[insuranceCompanyId]`, selectedInsuranceCompany?.value);
  //   finishModalAI();
  // };

  const setContractFile = () => {
    setFieldValue(`${modelName}[contract]`, selectedFile);
  };

  const getCommissionValue = value => {
    if (value === 'null') return 0;
    return value || 0;
  };

  const handleCompleteFieldsWithAI = fields => {
    const currentData = values.insurancePolicy;
    // currentData.responsibleEmail = fields.responsibleEmail;
    currentData.hiringPersonName = fields.hiringName;
    currentData.hiringPersonNationalIdentification = fields.hiringNationalIdentification;
    currentData.hiringPersonAddress = fields.hiringAddress;
    currentData.insuredPersonName = fields.insuredName;
    currentData.insuredPersonNationalIdentification = fields.insuredNationalIdentification;
    currentData.insuredPersonAddress = fields.insuredAddress;
    currentData.currency = fields.currency;
    currentData.insuranceCategoryCategory = selectedInsuranceType;
    currentData.affectCommissionPercentage = getCommissionValue(fields.affectCommission);
    currentData.exemptCommissionPercentage = getCommissionValue(fields.exemptCommission);
    currentData.parsedAffectCommissionPercentage = `${getCommissionValue(fields.affectCommission)} %`;
    currentData.parsedExemptCommissionPercentage = `${getCommissionValue(fields.affectCommission)} %`;
    currentData.subjectMatter = fields.generalDescription;
    currentData.affectPremium = fields.totalAffectPremium;
    currentData.exemptPremium = fields.totalExemptPremium;
    currentData.parsedAffectPremium = `${fields.totalAffectPremium || 0} ${fields.currency}`;
    currentData.parsedExemptPremium = `${fields.totalExemptPremium || 0} ${fields.currency}`;
    currentData.totalNetPremium = 0;
    currentData.policyNumber = fields.policyNumber;
    currentData.validityStart = validDate(fields.validityStart);
    currentData.validityEnd = validDate(fields.validityEnd);
    currentData.issueDate = validDate(fields.issueDate);
    currentData.renewalType = getRenewalType(fields.renewalType);
    currentData.insuranceCategoryName = selectedInsuranceCategory?.label || '';
    currentData.insuranceCategoryId = selectedInsuranceCategory?.value || '';
    currentData.dictionaryDetails = camelCaseRecursive(selectedInsuranceCategory?.dictionary_details) || [];
    currentData.dictionaryType = selectedInsuranceCategory?.dictionary_type || null;
    currentData.validateAiPrompt = selectedInsuranceCategory?.validate_ai_prompt || null;
    currentData.insuranceCompanyName = selectedInsuranceCompany?.label;
    currentData.insuranceCompanyFullName = selectedInsuranceCompany?.full_name;
    currentData.insuranceCompanyId = selectedInsuranceCompany?.value;
    currentData.contract = selectedFile;

    const { totalAffectPremium } = fields;
    const itemsLength = fields.items.length;

    const updatedItems = fields.items.map(item => {
      const { covers } = item;
      const allNetPremiumsEmpty = covers.every(cover => cover.netPremium === null || cover.netPremium === '');

      const replacementValue = totalAffectPremium / itemsLength;

      const updatedCovers = covers.map(cover => ({
        ...cover,
        netPremium: allNetPremiumsEmpty ? replacementValue : cover.netPremium
      }));

      return { ...item, covers: updatedCovers };
    });

    currentData.insuranceItemsAttributes = updatedItems.map(item => {
      const baseItem = {
        name: item.itemName,
        insuranceCoversAttributes: item.covers.map(cover => ({
          name: cover.coverDetail,
          amount: cover.coverAmount,
          netPremium: cover.netPremium,
          deductible: cover.deductible,
          parsedDeductible: `${cover.deductible || 0} ${fields.currency}`,
          coverageType: getCoverageType(cover.coverageType?.toLowerCase())
        }))
      };

      if (selectedInsuranceCategory?.dictionary_type === 'dictionary_2') {
        const { use, licensePlate, brand, model, year, motorNumber, chassisNumber, vehicleType } = item.vehicle || item;
        Object.assign(baseItem, {
          detail1: capitalizeString(use) || 'Particular',
          detail2: licensePlate,
          detail3: brand,
          detail4: model,
          detail5: year,
          detail6: motorNumber,
          detail7: chassisNumber,
          detail8: capitalizeString(vehicleType) || 'Liviano'
        });
      }

      return baseItem;
    });

    setInsurancePolicy({ ...currentData });
    // setInsuranceCategory();
    setPaymentPlan({ query: fields.paymentPlan });
    // setInsuranceCompany();
    setContractFile();
    setUseAi(true);
  };

  const handleCompletedStatus = message => {
    const jsonMatch = message.match(/```json\n([\s\S]*?)\n```/);
    // setResultAi(message);
    // setLoading(false);
    // handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Respuesta generada con éxito' }));
    if (jsonMatch) {
      try {
        const jsonObject = JSON.parse(jsonMatch[1]);
        const parsedObject = camelCaseEmptyStringRecursive(jsonObject);
        console.log('parsedObject: ', parsedObject);
        handleCompleteFieldsWithAI(parsedObject);
      } catch (error) {
        console.error('Error al parsear JSON:', error);
        finishModalAI();
      }
    }
  };

  const testCompleteFields = () => {
    const jsonMatch = testResponse.match(/```json\n([\s\S]*?)\n```/);
    dispatch(sendAlert({ kind: 'success', message: 'Respuesta generada con éxito' }));
    if (jsonMatch) {
      try {
        const jsonObject = JSON.parse(jsonMatch[1]);
        const parsedObject = camelCaseEmptyStringRecursive(jsonObject);
        console.log('parsedObject test: ', parsedObject);
        handleCompleteFieldsWithAI(parsedObject);
      } catch (error) {
        console.error('Error al parsear JSON:', error);
        finishModalAI();
      }
    }
  };

  const handleFailedStatus = () => {
    // setResultAi(message);
    // finishModalAI();
    setLoading(false);
    setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
  };

  const handleCheckThread = ({ theadId, runId, attempt = 1 }) => {
    const endpointRequest = getCheckThreadAiRequest();
    const sendParams = { thread_id: theadId, run_id: runId };
    endpointRequest({
      dispatch,
      formData: true,
      params: sendParams,
      successCallback: response => {
        const { message, status } = response.data;

        if (status === 'completed') {
          handleCompletedStatus(message);
        } else if (status === 'failed') {
          handleFailedStatus(message);
        } else {
          setTimeout(() => {
            handleCheckThread({ theadId, runId, attempt: attempt + 1 });
          }, RETRY_DELAY);
        }
      }
    });
  };

  const handleReadSuccessRequest = response => {
    if (!response.data) {
      setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
      setLoading(false);
      return;
    }
    const { thread_id: theadId, run_id: runId } = response.data;
    setTimeout(() => {
      handleCheckThread({ theadId, runId });
    }, RETRY_DELAY);
  };

  const completePrompt = () => {
    const basePrompt = generateReadPolicyPrompt;
    return basePrompt({
      categoryName: selectedInsuranceCategory?.label,
      companyName: selectedInsuranceCompany?.full_name,
      // extraPrompt: selectedInsuranceCompany.read_policy_prompt,
      additionalInstruction
    });
  };

  const handleReadPolicyAIRequest = () => {
    setLoading(true);
    setErrorMessage('');
    const sendParams = {
      prompt: completePrompt(),
      file: selectedFile,
      insurance_company_id: selectedInsuranceCompany.value
    };

    const endpointRequest = getReadPolicyAIRequest();

    endpointRequest({
      dispatch,
      params: { insurance_policy: sendParams },
      formData: true,
      successCallback: response => handleReadSuccessRequest(response),
      failureCallback: () => {
        setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
        setLoading(false);
      }
    });
  };

  const testHandleReadPolicyAIRequest = () => {
    setLoading(true);
    setErrorMessage('');
    setTimeout(() => {
      testCompleteFields();
    }, 3000);
  };

  const disabledButton = () => {
    if (!selectedInsuranceCategory && !selectedInsuranceCompany) return true;
    return loading || !selectedFile;
  };

  return (
    <>
      <ModalBodyIcon
        icon={icon}
        size="xl"
        iconVariant={iconVariant}
        content={modalTitle}
        confirmText={false}
        subContent={
          <>
            <Row>
              {errorMessage && (
                <Col md={12} className="d-flex align-items-center justify-content-center">
                  <Alert variant="danger" className="d-flex align-items-center justify-content-center">
                    <IconGG className="mr-2" icon="danger" size="sm" />
                    <p className="detail font-weight-bold m-0">{errorMessage}</p>
                  </Alert>
                </Col>
              )}

              <Col xs={12}>
                <UploadFile
                  abbr
                  fileAccept="image/*, application/pdf"
                  label="Póliza de seguro"
                  onChange={file => setSelectedFile(file)}
                />
              </Col>

              <Col md={12}>
                <InsuranceTypeRadio
                  selectedInsuranceType={selectedInsuranceType}
                  setSelectedInsuranceType={setSelectedInsuranceType}
                />
              </Col>

              <Col md={12}>
                <InsuranceCategorySelect
                  selectedInsuranceType={selectedInsuranceType}
                  selectedInsuranceCategory={selectedInsuranceCategory}
                  setSelectedInsuranceCategory={setSelectedInsuranceCategory}
                  disabled={!selectedInsuranceType}
                />
              </Col>

              <Col md={12}>
                <InsuranceCompanySelect
                  selectedInsuranceType={selectedInsuranceType}
                  setSelectedInsuranceCompany={setSelectedInsuranceCompany}
                  selectedInsuranceCompany={selectedInsuranceCompany}
                  disabled={!selectedInsuranceType}
                />
              </Col>

              <Col md={12}>
                <BasicTextArea
                  label="Instrucción adicional"
                  onChange={e => {
                    setAdditionalInstruction(e.target.value);
                  }}
                  placeholder="Instrucción adicional"
                  value={additionalInstruction}
                  row={3}
                />
              </Col>
            </Row>

            {/* <div>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{resultAi}</ReactMarkdown>
            </div> */}
          </>
        }
        formContent={
          <Row className="justify-content-center">
            <Col md={6} className="mt-4">
              <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
                Cancelar
              </Button>
            </Col>
            {enableButtonTest && (
              <Col md={6} className="mt-4">
                <Button
                  block
                  type="submit"
                  variant={variant}
                  className="no-shadow"
                  onClick={testHandleReadPolicyAIRequest}
                  disabled={loading}
                >
                  TEST
                </Button>
              </Col>
            )}
            <Col md={6} className="mt-4">
              <Button
                block
                type="submit"
                variant={variant}
                className="no-shadow"
                onClick={handleReadPolicyAIRequest}
                disabled={disabledButton()}
              >
                {submitButtonText()}
              </Button>
            </Col>
          </Row>
        }
        highlightedText=""
        highlightedVariant="white"
        handleModalClose={handleModalClose}
        submitVariant={variant}
      />
    </>
  );
};

export default ReadPolicyModalAi;
