import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
// import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';
import { useFormikContext, getIn } from 'formik';

import { checkAdminThreadAi } from '../../../requests/admin/lead';
import { ModalBodyIcon } from '../../Shared';
import { sendAlert } from '../../../actions/utils';
import { generateReadQuotationPrompt } from './prompts';
import { readAdminInsurancePolicyAiRequest } from '../../../requests/admin/adminInsurancePolicies';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { BasicTextArea } from '../../Utils/Input';
import testResponse from './testResponse';
import IconGG from '../../Utils/Icon';

const ReadQuotationModalAi = ({ handleModalClose, setInsurancePolicy, modelName, setUseAi }) => {
  const variant = 'submit';
  const iconVariant = 'success';
  const icon = 'check-o';
  const dispatch = useDispatch();
  // const [resultAi, setResultAi] = useState('');
  const [loading, setLoading] = useState(false);
  const [attachmentId, setAttachmentId] = useState(null);
  const [additionalInstruction, setAdditionalInstruction] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const RETRY_DELAY = 10000;
  const enableButtonTest = false;
  const { values } = useFormikContext();
  const { insuranceCategoryName } = values.insurancePolicy;

  const modalTitle = 'Leer cotización ✨';

  const submitButtonText = () => {
    return loading ? (
      <>
        Analizando...
        <span role="img" aria-label="sparkles">
          ✨
        </span>
        <Spinner animation="border" variant="primary" className="ml-2" />
      </>
    ) : (
      'Leer ✨'
    );
  };

  const getCoverageType = coverageType => {
    const requestMap = {
      afecta: 'affect',
      exenta: 'exempt'
    };

    return requestMap[coverageType] || '';
  };

  const finishModalAI = () => {
    setLoading(false);
    handleModalClose();
  };

  // const setAccountInformation = ({ query }) => {
  //   const sendParams = { for_selector: true, query };
  //   debounceIndexAdminAccountRequest({
  //     dispatch,
  //     params: sendParams,
  //     successCallback: response => {
  //       const selectedAccount = response.data.data[0];
  //       setFieldValue(`${modelName}[insuranceBrokerId]`, selectedAccount?.insurance_broker_id || '');

  //       setTimeout(() => {
  //         setFieldValue(`${modelName}[accountId]`, selectedAccount?.value || '');
  //         setFieldValue(`${modelName}[accountName]`, selectedAccount?.label || '');
  //         setFieldValue(`${modelName}[executiveManagerId]`, selectedAccount?.executive_manager_id || '');
  //         setFieldValue(`${modelName}[responsibleEmail]`, selectedAccount?.primary_contact_email);
  //         setFieldValue(`${modelName}[collectionEmail]`, selectedAccount?.primary_contact_email);
  //         finishModalAI();
  //       }, 1500);
  //     }
  //   });
  // };

  // const setInsuranceCategory = () => {
  //   setFieldValue(`${modelName}[insuranceCategoryName]`, selectedInsuranceCategory?.label || '');
  //   setFieldValue(`${modelName}[insuranceCategoryId]`, selectedInsuranceCategory?.value || '');
  //   setFieldValue(
  //     `${modelName}[dictionaryDetails]`,
  //     camelCaseRecursive(selectedInsuranceCategory?.dictionary_details) || []
  //   );
  //   setFieldValue(`${modelName}[dictionaryType]`, selectedInsuranceCategory?.dictionary_type || null);
  // };

  // const setInsuranceCompany = () => {
  //   setFieldValue(`${modelName}[insuranceCompanyName]`, selectedInsuranceCompany?.label);
  //   setFieldValue(`${modelName}[insuranceCompanyFullName]`, selectedInsuranceCompany?.full_name);
  //   setFieldValue(`${modelName}[insuranceCompanyId]`, selectedInsuranceCompany?.value);
  //   finishModalAI();
  // };

  const handleCompleteFieldsWithAI = fields => {
    const currentData = values.insurancePolicy;
    // currentData.responsibleEmail = fields.responsibleEmail;
    currentData.hiringPersonName = fields.hiringName;
    currentData.hiringPersonNationalIdentification = fields.hiringNationalIdentification;
    currentData.hiringPersonAddress = fields.hiringAddress;
    currentData.insuredPersonName = fields.insuredName;
    currentData.insuredPersonNationalIdentification = fields.insuredNationalIdentification;
    currentData.insuredPersonAddress = fields.insuredAddress;
    currentData.currency = fields.currency;
    // currentData.insuranceCategoryCategory = selectedInsuranceType;
    // currentData.affectCommissionPercentage = fields.affectCommissionPercentage;
    // currentData.parsedAffectCommissionPercentage = fields.parsedAffectCommissionPercentage;
    // currentData.exemptCommissionPercentage = fields.exemptCommissionPercentage;
    // currentData.parsedExemptCommissionPercentage = fields.parsedExemptCommissionPercentage;
    currentData.affectPremium = fields.totalAffectPremium;
    currentData.exemptPremium = fields.totalExemptPremium;
    currentData.totalNetPremium = 0;
    // currentData.parsedAffectPremium = fields.parsedTotalAffectPremium;

    const { totalAffectPremium } = fields;
    const itemsLength = fields.items.length;

    const updatedItems = fields.items.map(item => {
      const { covers } = item;
      const allNetPremiumsEmpty = covers.every(cover => cover.netPremium === null || cover.netPremium === '');

      const replacementValue = totalAffectPremium / itemsLength;

      const updatedCovers = covers.map(cover => ({
        ...cover,
        netPremium: allNetPremiumsEmpty ? replacementValue : cover.netPremium
      }));

      return { ...item, covers: updatedCovers };
    });

    currentData.insuranceItemsAttributes = updatedItems.map(item => {
      const baseItem = {
        name: item.itemName,
        insuranceCoversAttributes: item.covers.map(cover => ({
          name: cover.coverDetail,
          amount: cover.coverAmount,
          netPremium: cover.netPremium,
          coverageType: getCoverageType(cover.coverageType?.toLowerCase())
        }))
      };

      // if (dictionaryType === 'dictionary_2') {
      //   const { use, licensePlate, brand, model, year, motorNumber, chassisNumber, vehicleType } = item.vehicle || item;
      //   Object.assign(baseItem, {
      //     detail1: capitalizeString(use) || 'Particular',
      //     detail2: licensePlate,
      //     detail3: brand,
      //     detail4: model,
      //     detail5: year,
      //     detail6: motorNumber,
      //     detail7: chassisNumber,
      //     detail8: capitalizeString(vehicleType) || 'Liviano'
      //   });
      // }

      return baseItem;
    });

    // setAccountInformation({ query: fields.accountName });
    setInsurancePolicy({ ...currentData });
    // setInsuranceCategory();
    // setInsuranceCompany();
    setUseAi(true);
    finishModalAI();
  };

  const handleCompletedStatus = message => {
    const jsonMatch = message.match(/```json\n([\s\S]*?)\n```/);
    // setResultAi(message);
    setLoading(false);
    // handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Respuesta generada con éxito' }));
    if (jsonMatch) {
      try {
        const jsonObject = JSON.parse(jsonMatch[1]);
        const parsedObject = camelCaseEmptyStringRecursive(jsonObject);
        console.log('parsedObject: ', parsedObject);
        handleCompleteFieldsWithAI(parsedObject);
      } catch (error) {
        console.error('Error al parsear JSON:', error);
        setLoading(false);
        setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
      }
    }
  };

  // const testCompleteFields = () => {
  //   setLoading(true);
  //   dispatch(sendAlert({ kind: 'success', message: 'Respuesta generada con éxito' }));
  //   const parsedObject = camelCaseEmptyStringRecursive(testResponse);
  //   handleCompleteFieldsWithAI(parsedObject);
  // };

  const testCompleteFields = () => {
    const jsonMatch = testResponse.match(/```json\n([\s\S]*?)\n```/);
    dispatch(sendAlert({ kind: 'success', message: 'Respuesta generada con éxito' }));
    if (jsonMatch) {
      try {
        const jsonObject = JSON.parse(jsonMatch[1]);
        const parsedObject = camelCaseEmptyStringRecursive(jsonObject);
        console.log('parsedObject test: ', parsedObject);
        handleCompleteFieldsWithAI(parsedObject);
      } catch (error) {
        console.error('Error al parsear JSON:', error);
        setLoading(false);
        setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
      }
    }
  };

  const handleFailedStatus = () => {
    // setResultAi(message);
    // finishModalAI();
    setLoading(false);
    setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
  };

  const handleCheckThread = ({ theadId, runId, attempt = 1 }) => {
    const endpointRequest = checkAdminThreadAi;
    const sendParams = { thread_id: theadId, run_id: runId };
    endpointRequest({
      dispatch,
      formData: true,
      params: sendParams,
      successCallback: response => {
        const { message, status } = response.data;

        if (status === 'completed') {
          handleCompletedStatus(message);
        } else if (status === 'failed') {
          handleFailedStatus(message);
        } else {
          setTimeout(() => {
            handleCheckThread({ theadId, runId, attempt: attempt + 1 });
          }, RETRY_DELAY);
        }
      }
    });
  };

  const handleReadSuccessRequest = response => {
    if (!response.data) {
      setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
      setLoading(false);
      return;
    }
    const { thread_id: theadId, run_id: runId } = response.data;
    setTimeout(() => {
      handleCheckThread({ theadId, runId });
    }, RETRY_DELAY);
  };

  const completePrompt = () => {
    const basePrompt = generateReadQuotationPrompt;
    return basePrompt({
      // extraPrompt: selectedInsuranceCompany.read_policy_prompt,
      additionalInstruction,
      categoryName: insuranceCategoryName
    });
  };

  const handleReadPolicyAIRequest = () => {
    setLoading(true);
    setErrorMessage('');
    const sendParams = {
      prompt: completePrompt(),
      attachment_id: attachmentId
    };

    readAdminInsurancePolicyAiRequest({
      dispatch,
      params: { insurance_policy: sendParams },
      formData: true,
      successCallback: response => handleReadSuccessRequest(response),
      failureCallback: () => {
        setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
        setLoading(false);
      }
    });
  };

  const disabledButton = () => {
    return loading || !attachmentId;
  };

  const setQuotationFile = () => {
    const savedFile = getIn(values, `${modelName}[quotationInfo]`);
    setAttachmentId(savedFile?.id);
  };

  useEffect(() => {
    setQuotationFile();
  }, []);

  return (
    <>
      <ModalBodyIcon
        icon={icon}
        size="xl"
        iconVariant={iconVariant}
        content={modalTitle}
        confirmText={false}
        subContent={
          <>
            <Row>
              {errorMessage && (
                <Col md={12} className="d-flex align-items-center justify-content-center">
                  <Alert variant="danger" className="d-flex align-items-center justify-content-center">
                    <IconGG className="mr-2" icon="danger" size="sm" />
                    <p className="detail font-weight-bold m-0">{errorMessage}</p>
                  </Alert>
                </Col>
              )}

              <Col md={12}>
                <BasicTextArea
                  label="Instrucción adicional"
                  onChange={e => {
                    setAdditionalInstruction(e.target.value);
                  }}
                  placeholder="Instrucción adicional"
                  value={additionalInstruction}
                  row={3}
                />
              </Col>
            </Row>

            {/* <div>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{resultAi}</ReactMarkdown>
            </div> */}
          </>
        }
        formContent={
          <Row className="justify-content-center">
            <Col md={6} className="mt-4">
              <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
                Cancelar
              </Button>
            </Col>
            {enableButtonTest && (
              <Col md={6} className="mt-4">
                <Button
                  block
                  type="submit"
                  variant={variant}
                  className="no-shadow"
                  onClick={testCompleteFields}
                  disabled={loading}
                >
                  TEST
                </Button>
              </Col>
            )}
            <Col md={6} className="mt-4">
              <Button
                block
                type="submit"
                variant={variant}
                className="no-shadow"
                onClick={handleReadPolicyAIRequest}
                disabled={disabledButton()}
              >
                {submitButtonText()}
              </Button>
            </Col>
          </Row>
        }
        highlightedText=""
        highlightedVariant="white"
        handleModalClose={handleModalClose}
        submitVariant={variant}
      />
    </>
  );
};

export default ReadQuotationModalAi;
