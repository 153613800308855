import React, { useState } from 'react';
import { SimpleCenteredModal } from '../components';

const useModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);

  const handleModalShow = ({ body = null }) => {
    setShowModal(true);
    if (body && (typeof body === 'object' || React.isValidElement(body))) {
      setModalBody(body);
    } else {
      console.error('Invalid modal content: Body must be a JSX or a react component');
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const ModalComponent = ({ ...modalProps }) => {
    return <SimpleCenteredModal body={modalBody} show={showModal} onHide={handleModalClose} {...modalProps} />;
  };

  return {
    ModalComponent,
    handleModalShow,
    handleModalClose
  };
};

export default useModal;
