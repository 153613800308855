import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { BtnTooltip } from '../Button';
import { ButtonTooltip } from '../Tooltips';

const InfoCard = ({ children, className, footerButton, iconBtnComponent, object, title, tooltipText }) => (
  <div className={`info-card ${className || ''}`}>
    <div className="content">
      {(title || iconBtnComponent || tooltipText) && (
        <header className="info-card--header">
          {title && <p className="section-title capitalize-text">{title}</p>}
          {iconBtnComponent && iconBtnComponent}
          {tooltipText && (
            <ButtonTooltip html={false} tooltipText={tooltipText}>
              ?
            </ButtonTooltip>
          )}
        </header>
      )}

      {object && (
        <div className="info-card--body">
          {Object.entries(object).map(([key, value], index) => (
            <ElementTypeOptions key={`${key}-${index.toString()}`} element={value} elementType={value.elementType} />
          ))}
        </div>
      )}

      {children && <div className="info-card--body">{children}</div>}
    </div>

    {footerButton && <footer className="info-card--footer">{footerButton}</footer>}
  </div>
);

const ElementTypeOptions = ({ element, elementType }) => {
  return elementType.string ? (
    <StringComponent element={element} elementType={element.elementType} />
  ) : (
    (elementType.array && <ArrayComponent element={element} />) ||
      (elementType.arrayCommaSeparated && <ArrayCommaSeparatedComponent element={element} />) ||
      (elementType.file && <FileComponent file={element.item} elementType={element.elementType} />) ||
      (elementType.link && <LinkComponent element={element} elementType={element.elementType} />)
  );
};

const StringComponent = ({ element, elementType }) => {
  const elementClass = elementType.subelement ? 'list-subelement' : 'list-element';
  const keyTagMargin = ((element.key || element.value) && 'ml-4') || '';
  const keyTagColor = element.keyTagColor || 'bg-light-gray';

  return element.tooltipText ? (
    <StringWithTooltipComponent element={element} elementType={element.elementType} />
  ) : (
    <p
      className={`${elementClass} ${elementType.keyBold ? 'font-weight-bold' : ''}`}
      style={element.preWrap ? { whiteSpace: 'pre-wrap' } : {}}
    >
      {element.key && `${element.key}: `}
      {element.value || ''}
      {element.keyTag && (
        <span className={`font-weight-bold rounded px-2 small text-uppercase ${keyTagMargin} ${keyTagColor}`}>
          {element.keyTag}
        </span>
      )}
    </p>
  );
};

const StringWithTooltipComponent = ({ element, elementType }) => {
  const elementClass = elementType.subelement ? 'list-subelement' : 'list-element';
  const keyTagMargin = ((element.key || element.value) && 'ml-4') || '';
  const keyTagColor = element.keyTagColor || 'bg-light-gray';

  return (
    <div className={`${elementClass} ${elementType.keyBold ? 'font-weight-bold' : ''}`}>
      {element.key && `${element.key}: `}
      {element.value || ''}
      {element.keyTag && (
        <span className={`font-weight-bold rounded px-2 small text-uppercase ${keyTagMargin} ${keyTagColor}`}>
          {element.keyTag}
        </span>
      )}

      {element.tooltipText && (
        <ButtonTooltip tooltipText={element.tooltipText} className="ml-2">
          ?
        </ButtonTooltip>
      )}
    </div>
  );
};

const LinkComponent = ({ element, elementType }) => {
  const elementClass = elementType.subelement ? 'list-subelement' : 'list-element';

  return (
    <div className="d-flex">
      <p className={`${elementClass} ${elementType.link ? 'grid-text' : ''}`}>
        {element.key && `${element.key}: `}
        {elementType.link && (
          <Link to={element.url}>
            <Button variant="link" className="p-0 ml-2">
              {element.value}
            </Button>
          </Link>
        )}
      </p>
    </div>
  );
};

const ArrayComponent = ({ element }) => (
  <>
    {(element.key || element.value || element.keyTag) && (
      <StringComponent element={element} elementType={element.elementType} />
    )}

    <div className="d-flex flex-column">
      {element.items.map((item, index) => (
        <ElementTypeOptions
          key={`element-type-option-${index.toString()}`}
          element={item}
          elementType={item.elementType}
        />
      ))}
    </div>
  </>
);

const CommaSeparatedComponent = ({ element, elementType, index, elements }) => {
  const isFirstElement = index === 0;

  return (
    <div className="d-flex">
      <p className={`${elementType.link ? 'grid-text' : ''}`} style={isFirstElement ? { marginLeft: '4rem' } : {}}>
        <span key={element.key}>
          {isFirstElement && '- '}
          {element.key && `${element.key}: `}
          {elementType.link ? (
            <Link to={element.url}>
              <Button variant="link" className="p-0 ml-2">
                {element.value}
              </Button>
            </Link>
          ) : (
            element.value
          )}
          {index < elements.length - 1 && ', '}
        </span>
      </p>
    </div>
  );
};

const ArrayCommaSeparatedComponent = ({ element }) => (
  <>
    {(element.key || element.value || element.keyTag) && (
      <StringComponent element={element} elementType={element.elementType} />
    )}

    <div className="d-flex flex-row">
      {element.items.map((item, index) => (
        <CommaSeparatedComponent
          key={`element-type-option-${index.toString()}`}
          element={item}
          elementType={item.elementType}
          index={index}
          elements={element.items}
        />
      ))}
    </div>
  </>
);

const FileComponent = ({ file, elementType }) => {
  const elementTitle = file.title ? `${file.title}${file.name ? ': ' : ''}` : false;
  const elementClass = elementType.subelement ? 'list-subelement' : 'list-element';
  const linkColor = file.url ? (elementTitle && 'text-color-primary-light-blue') || '' : 'text-color-danger';

  return (
    <div className="d-flex">
      <p className={`${elementClass} ${elementType.link ? 'grid-text' : ''}`}>
        {elementTitle}
        {elementType.link ? (
          <a href={file.url} target="_blank" rel="noreferrer" className={`grid-text__link ml-2 ${linkColor}`}>
            {file.name}
          </a>
        ) : (
          <Button variant="link" className="p-0 ml-2" onClick={file.onClick}>
            {file.name}
          </Button>
        )}
      </p>
      {file.canSendEmail && file.sendEmailOnClick && (
        <BtnTooltip
          icon="mail"
          iconSize="sm"
          variant="transparent"
          className="black ml-2"
          tooltipText="Enviar"
          onClick={file.sendEmailOnClick}
        />
      )}
      {file.canEdit && file.editOnClick && (
        <BtnTooltip
          icon="pen"
          iconSize="sm"
          variant="transparent"
          className="edit-btn"
          tooltipText="Editar"
          onClick={file.editOnClick}
        />
      )}
      {file.canDelete && file.deleteOnClick && (
        <BtnTooltip
          icon="trash"
          iconSize="sm"
          variant="transparent"
          className="danger"
          tooltipText="Eliminar"
          onClick={file.deleteOnClick}
        />
      )}
    </div>
  );
};

export default InfoCard;
