// eslint-disable-next-line import/prefer-default-export
export const generateReadQuotationPrompt = ({ additionalInstruction, categoryName }) => {
  return `
Analiza la cotización de seguro proporcionada del ramo "${categoryName}" y extrae la información relevante basada en un solo plan.
La salida debe estar estructurada en formato JSON con la siguiente estructura:

\`\`\`
{
		"hiring_name": "Nombre del contratante",
		"hiring_national_identification": "Rut del contratante",
		"hiring_address": "Dirección del contratante",
		"insured_name": "Nombre del asegurado",
		"insured_national_identification": "RUT del asegurado",
		"insured_address": "Dirección del asegurado",
		"currency": "Moneda usada en la cotización (puede ser 'UF' o 'USD')",
		"payment_plan_name": "Cuotas pactadas por el plan de pago en formato numérico",
		"total_affect_premium": Prima afecta total de la cotización en valor numérico.
		"total_exepmt_premium": Prima exenta total de la cotización en valor numérico.
		"total_premium": Prima total de la cotización en valor numérico.
		"total_amount": Monto asegurado total en valor numérico.
		"items": [
				{
						"item_name": "Nombre del ítem general en la cotización",
						"covers": [ // Simplifica todas las coberturas en una sola general.
								{
										"cover_detail": "Cobertura general",
										"cover_amount": "Monto asegurado en valor numérico del plan seleccionado",
										"fee": "Tasa de la cobertura en valor numérico",
										"net_premium": "Prima neta total de la cobertura en valor numérico del plan seleccionado",
										"coverage_type": "Tipo de prima o cobertura (afecta o exenta)"
								}
						]
				}
		// Repetir esta estructura para cada ítem de la cotización
	]
}
\`\`\`

## Reglas de extracción:

- Seleccionar un solo plan de la cotización, de preferencia el más completo o indicado por el usuario.
- Convertir los valores monetarios a formato numérico.
- Selecciona los valores en UF o USD, no seleccionar valores en pesos ($).
- Extraer coberturas generales sin repetir detalles específicos de cada una.
- Ignorar información irrelevante para la estructura JSON (e.g., términos generales, cláusulas adicionales, información de contacto innecesaria).
- Los datos que no encuentres o que no estén especificados, déjalos como "null".
- Los ítems a veces no están especificados. En ese caso, deberás generar los ítems en base al plan seleccionado con la información del plan.
- Los "items", corresponden a los ítems de la cotización de seguros. Una cotización puede tener más de 1 ítem.
- Cada ítem, puede tener más de una cobertura. Las coberturas van en la llave "covers", dentro de cada "item".
- Si un ítem tiene más de una cobertura, simplifica y resume las coberturas en una general.
- Las fechas si o si deben estar en formato "dd/mm/yyyy".
- Retorna sólo el JSON.
- Extrae todos los ítems del documento, asegurándote de incluir todos los detalles de cada uno, sin omitir ninguno. 
  Cada ítem debe incluir sus coberturas y la información completa del vehículo asociado. Si el documento contiene 30 ítems, 
  quiero ver los 30 ítems en el resultado.

## Instrucciónes adicionales:

- Sólo si el ramo es "Vehículos", agrega estás llaves a cada ítem y extrae los valores.

\`\`\`
{
  license_plate: "Patente o placa única del vehículo",
  brand: "Marca del vehículo",
  model: "Modelo del vehículo",
  year:  "Año del vehículo",
  use:  "Uso del vehículo (Particular o Comercial)"
  motor_number: "Número del motor del vehículo",
  chassis_number: "Número de chasis del vehículo"
  vehicle_type: "Tipo de vehícuilo (Liviano o Pesado)"
}
\`\`\`

${additionalInstruction || ''}
`;
};
